#sign-up-term {
  margin-top: 12px;
  line-height: 1.5;
  font-size: 12px;
  display: inline-block;
  color: #878787;
}

.ant-modal-mask {
  backdrop-filter: blur(5px);
}

.ant-modal .ant-modal-content {
  padding: 5px 10px;
}

.login-modal-section {
  .signup-header {
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 2;
    position: absolute;
    top: 0;
    height: 80px;
    left: 0;
    backdrop-filter: blur(10px);
  }

  .login-modal-title {
    text-align: center;
    font-size: 29px;
    line-height: 1.3;
    color: white;
    margin: 0 auto;
    padding: 5px 40px;
    background-color: #4096ff;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    z-index: 100;

    .number {
      font-size: 22px;
      display: inline-block;
      // white-space: pre;
      width: max-content;
    }

    @media only screen and (max-width: 576px) {
      font-size: 23px;
    }

    @media only screen and (max-width: 420px) {
      padding: 5px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 120px;
      min-height: 40px;
    }
  }

  .wrap-top-modal {
    margin-top: 20px;

    .body-modal {
      padding: 10px 10px;
      padding-bottom: 0;

      .body-modal-ceate-account {
        font-size: 1.25rem;
        text-align: center;
        margin: 0 0 10px 0;
        font-weight: 500;
        line-height: 1.2;
        color: #7691ff !important;

        p {
          margin: 0;
        }

        @media screen and (max-width: 768px) {
          margin-top: 15px !important;
          font-size: 18px !important;
        }
      }

      .payment-details {
        margin-bottom: 20px;

        .df-details {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .left-payment {
            p {
              margin: 0;
              font-size: 30px;
              color: #4096ff;
            }
          }

          .right-payment {
            text-align: right;
            line-height: 1;

            p {
              color: #4096ff;
              font-size: 24px;
              margin: 0;
            }

            span {
              font-size: 12.8px;
            }
          }
        }

        .time-span {
          text-align: right;
          line-height: 0.5;
        }
      }

      .mt-4 {
        margin-top: 30px;
      }

      .modal-card-form {
        position: relative;
        margin-bottom: 10px;

        .border {
          border: 1px solid#d9d9d9;
          padding: 10px 5px;
          border-radius: 5px;
        }
      }

      .modal-login-form {
        position: relative;
        margin-bottom: 10px;

        input {
          height: 49px;
        }

        // tooltip validation
        .term-checkbox {
          display: flex;
          align-items: flex-start;
          gap: 10px;
          margin-top: 20px;
          position: relative;
          justify-content: center;
          text-align: center;

          span {
            a {
              color: rgba(0, 0, 0, 0.88);
              text-decoration: underline;
            }
          }

          .sign-up-tooltip {
            position: absolute;
            top: 31px;
            left: 140px;
            transform: translateX(-50%);
            background-color: #333333;
            color: #ffffff;
            padding: 4px 8px;
            border-radius: 5px;
            font-size: 13px;
            z-index: 1000;

            @media screen and (max-width: 768px) {
              padding: 4px 8px;
              font-size: 13px;
              width: max-content;
            }

            @media screen and (max-width: 361px) {
              top: 52px;
              left: 140px;

              .term-checkbox {
                span {
                  font-size: 13px;
                }
              }
            }

            &:before {
              content: "";
              position: absolute;
              background-image: url("../../assets/home/arrow_icon.svg");
              top: -8px;
              bottom: auto;
              left: 5px;
              right: auto;
              width: 100%;
              max-width: 20px;
              height: 10px;
            }
          }

          input {
            -webkit-appearance: none;
            height: 18px;
            width: 18px;
            opacity: 1;
            position: relative;
            border: 0;
            background: transparent;
            appearance: none;
            flex: 0 0 18px;
            align-self: center;
            margin: 0;
            outline: none;
            box-shadow: none;
            color: #d1d1d7;

            &:checked {
              color: #1664fa;
            }

            &:before {
              content: "";
              position: absolute;
              border: 2px solid currentColor;
              border-radius: 0.25rem;
              z-index: 1;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
            }

            &:after {
              position: absolute;
              content: "";
              background-color: currentColor;
              border-radius: 3px;
              top: 4px;
              left: 4px;
              right: 4px;
              bottom: 4px;
              z-index: 2;
              opacity: 0;
            }

            &:checked::after {
              opacity: 1;
            }
          }
        }

        .icon-container {
          font-size: 25px;
          display: flex;
          gap: 5px;
          position: absolute;
          bottom: 7px;
          right: 7px;
        }
      }

      .exp-cvv-modal {
        display: flex;
        gap: 20px;
        padding-bottom: 10px;

        .df-left,
        .df-right {
          width: 50%;

          .border {
            border: 1px solid #d9d9d9;
            padding: 10px 5px;
            border-radius: 5px;
          }
        }

        .ant-form-item {
          margin-bottom: 10px;
        }

        .ant-picker {
          width: 100%;
          height: 40px;
        }

        input {
          width: 100%;
          height: 40px;
        }
      }

      .forget-pass-modal {
        a {
          color: #858585;
        }
      }

      .postalCode-div {
        input {
          border: 1px solid #d9d9d9;
          padding: 8px 5px;
          border-radius: 5px;
        }
      }

      .submit-btn-modal {
        width: 100%;
        margin-top: 20px;

        .on-mobile {
          display: none;
          position: relative;
          overflow: hidden;
          width: 100%;
          height: 100%;
          padding: 12px 20px;
          border: 1px solid currentColor;
          border-radius: 14px;
          transition: 0.5s ease-in-out;
          z-index: 1;
          background-color: #1664fa !important;
          color: white;
          font-weight: 700;
          font-size: 20px;
          cursor: pointer;

          @media screen and (max-width: 425px) {
            display: block !important;
          }
        }

        .on-desk {
          @media screen and (max-width: 425px) {
            display: none !important;
          }
        }

        button {
          box-shadow: none !important;
        }

        .hl_cta_wrap {
          position: relative;
          overflow: hidden;
          width: 100%;
          height: 100%;
          padding: 12px 20px;
          border: 1px solid currentColor;
          border-radius: 14px;
          transition: 0.5s ease-in-out;
          z-index: 1;
          background-color: transparent !important;
          color: #1664fa;
          font-weight: 700;
          font-size: 20px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            color: #fff !important;
          }

          @media only screen and (max-width: 426px) {
            font-size: 17px;
          }
        }
      }

      select {
        -webkit-appearance: none;
        width: 100%;
        border: 1px solid #d9d9d9;
        padding: 10px 5px;
        background-color: #ffff;
        border-radius: 5px;
        font-size: 16px;
        color: black;

        &:focus {
          outline: none;
        }

        option {
          -webkit-appearance: none;
          background-color: #ffff;
          border-radius: 1vh;
          border-color: transparent;
        }
      }
    }
  }
}

.ant-select {
  width: 100% !important;

  .ant-select-selector {
    width: 100% !important;
    height: 40px !important;

    input {
      width: 100% !important;
      height: 40px !important;
    }

    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }
  }
}

.ant-form-item {
  margin-bottom: 0;
}

.label-requred-star {
  color: red;
}

.errorFelid {
  border-color: red !important;
}

.space-gpay {
  padding: 0 0 20px !important;
}

// Tracelo Locating Animation
.ant-modal-wrap {
  .animation-model {
    width: 100% !important;
    max-width: 400px;

    .ant-modal-body {
      padding-top: 0px !important;

      .wrap-top-modal {
        margin-top: 0px;
      }

      .login-modal-section {
        padding: 0px 0px !important;
      }
    }

    .ant-modal-content {
      padding: 90px 30px;
      background-image: url("../../assets/home/backmap_img.jpg");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  .animation__main_wrapper {
    .animation_wrapper {
      .animation__title {
        width: 100%;
        display: inline-block;
        vertical-align: top;
        margin-bottom: 20px;

        .animation__text {
          width: 100%;
          display: inline-block;
          vertical-align: top;
          text-align: center;
          margin-bottom: 20px;

          p {
            font-size: 18px;
            line-height: 1.3;
            text-align: center;
            margin: 0px 0px;
            position: relative;
            padding-right: 17px;
            display: inline-block;
            color: #777777;

            &:after {
              content: "";
              position: absolute;
              top: auto;
              bottom: 4px;
              left: auto;
              right: 0px;
              width: 4px;
              aspect-ratio: 1;
              border-radius: 100%;
              animation: animationdots 1s infinite linear alternate;
              overflow: hidden;
            }
          }
        }

        .locate__number {
          span {
            width: 100%;
            height: 100%;
            display: inline-block;
            vertical-align: top;
          }

          a {
            width: 100%;
            display: inline-block;
            vertical-align: top;
            font-weight: 700;
            font-size: 22px;
            line-height: 1.3;
            text-align: center;
            color: #1664fa;
          }
        }
      }

      .animated__table-list {
        width: 100%;
        display: inline-block;
        vertical-align: top;

        .animated__table-row {
          width: 100%;
          min-height: 56px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px 15px;
          border: 1px solid #24537b;
          box-sizing: border-box;
          border-radius: 10px;

          &:not(:last-child) {
            margin-bottom: 10px;
          }

          .animated__table-title {
            font-size: 16px;
            font-weight: 500;
            line-height: normal;
            color: #24537b;
          }

          .animated__table-value {
            text-align: right;
            font-weight: 500;
            font-size: 14px;
            line-height: normal;
            color: #1664fa;
          }

          .loading {
            .rotating-border {
              width: 22px;
              height: 22px;
              transform-origin: center;

              .circle {
                fill: none;
                stroke: #1664fa;
                stroke-width: 8;
                stroke-dasharray: 283;
                stroke-dashoffset: 283;
                transform-origin: center;
                transform: rotate(-90deg);
                animation: drawBorder 2s infinite linear forwards,
                  rotateBorder 2s infinite linear forwards;
              }
            }
          }

          .animated_defined {
            background-color: #1664fa;
            color: #ffffff;
            padding: 8px 15px;
            border-radius: 5px;
            display: flex;
            align-items: center;

            img {
              margin-right: 5px;
            }
          }

          .animated_blurry_title {
            filter: blur(4px);
            user-select: none;
            cursor: default;
          }
        }
      }
    }
  }

  .wrap-top-modal {
    margin-top: 0px;
  }
}

@media screen and (max-width: 767px) {
  .ant-modal-wrap {
    .animation-model {
      .ant-modal-content {
        padding: 70px 30px !important;
      }
    }
  }
}

@media screen and (min-height: 481px) and (max-height: 740px) {
  .ant-modal-wrap {
    .animation-model {
      .ant-modal-content {
        padding: 40px 30px !important;
      }
    }
  }
}

@media screen and (max-width: 435px) {
  .ant-modal-wrap {
    .animation-model {
      .ant-modal-content {
        padding: 40px 15px !important;
        margin: 0px 15px;
      }
    }

    .animation__main_wrapper {
      .animation_wrapper {
        .animation__title {
          .animation__text {
            p {
              font-size: 16px;
            }

            margin-bottom: 10px;
          }

          margin-bottom: 10px;
        }

        .animated__table-list {
          .animated__table-row {
            padding: 12px 10px;
          }
        }
      }
    }
  }
}

@keyframes rotateBorder {
  0% {
    transform: rotate(-90deg);
  }

  100% {
    transform: rotate(270deg);
  }
}

@keyframes drawBorder {
  0% {
    stroke-dashoffset: 283;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

.animated__table-row {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.animated__table-row.visible {
  opacity: 1;
}

.hidden {
  display: none;
}

.visible {
  display: flex;
}

@keyframes animationdots {
  0% {
    box-shadow: 8px 0 #777777, -8px 0 rgb(0 0 0 / 15%);
    background-color: #777777;
  }

  33% {
    box-shadow: 8px 0 #777777, -8px 0 rgb(0 0 0 / 15%);
    background: rgb(0 0 0 / 15%);
  }

  66% {
    box-shadow: 8px 0 rgb(0 0 0 / 15%), -8px 0 #777777;
    background: rgb(0 0 0 / 15%);
  }

  100% {
    box-shadow: 8px 0 rgb(0 0 0 / 15%), -8px 0 #777777;
    background: #777777;
  }
}

@media only screen and (max-width: 480px) {
  .locating-login-modal
    .login-modal-section
    .wrap-top-modal
    .body-modal
    .body-modal-ceate-account {
    margin-top: 0px;
  }

  .locating-login-modal .login-modal-section .wrap-top-modal {
    margin-top: 0px;
  }

  .locating-login-modal .ant-modal-body {
    padding-top: 40px !important;
  }

  .locating-login-modal {
    .ant-modal-close {
      margin-top: 10px !important;
      width: 100% !important;
      right: 5px;
      top: 5px;

      .ant-modal-close-x {
        justify-content: flex-end;
        margin-right: 10px;
      }
    }
  }
}

// Ts-5 update changes
.auth-divider {
  width: 100%;
  align-items: center;
  display: flex;
  margin-top: 2rem !important;
  margin-bottom: 1.5rem !important;

  &.top-divider {
    margin-top: 2rem !important;
  }

  .line {
    flex: 1;
    height: 1px;
    background-color: #d9d9d9;

    &.left {
      margin-left: 1rem !important;
    }

    &.right {
      margin-right: 1rem !important;
    }
  }

  .or-text {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
    font-size: 16px;
    font-family: "GT Walsheim Pro";
  }
}

.auth-divider {
  margin-top: 2rem !important;
}

.auth-divider.top-divider {
  margin-top: 2rem !important;
}

@media screen and (max-width: 361px) {
  .login-modal-section {
    .wrap-top-modal {
      .body-modal {
        .modal-login-form {
          .term-checkbox {
            .sign-up-tooltip {
              left: 130px;
              top: 44px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .checkout_payment_modal {
    .login-modal-section {
      .wrap-top-modal {
        .body-modal {
          .payment-details {
            .df-details {
              .left-payment {
                p {
                  font-size: 22px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.checkout_payment_modal {
  .wrap-top-modal {
    .body-modal {
      max-height: calc(100vh - 140px);
    }
  }
}

@media screen and (max-width: 991px) {
  .checkout_payment_modal {
    .login-modal-section {
      .wrap-top-modal {
        .body-modal {
          .payment-details {
            .df-details {
              .left-payment {
                p {
                  font-size: 22px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.checkout_payment_modal {
  .modal_number_info {
    position: absolute;
    left: 0;
    z-index: 999;
    top: 0;
    width: 100%;
    background-color: #ffffff;
    border-radius: 8px 8px 0px 0px;
  }

  .wrap-top-modal {
    padding-top: 30px;
  }

  button.ant-modal-close {
    background-color: transparent !important;
  }
}

// New Locating Payment Css

.login-modal-section {
  .wrap-top-modal {
    .body-modal {
      .payment_modal_area {
        .df-details {
          .left-payment {
            p {
              font-size: 25px;
              line-height: 1.2;
            }
          }

          .right-payment {
            p {
              font-size: 25px !important;
              line-height: 1.2;
            }
          }
        }

        .right-payment {
          text-align: right;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .login-modal-section {
    .wrap-top-modal {
      .body-modal {
        .payment_modal_area {
          .df-details {
            .left-payment {
              p {
                font-size: 22px !important;
              }
            }

            .right-payment {
              p {
                font-size: 22px !important;
              }
            }
          }

          .right-payment {
            span {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  .ant-modal.signup-info-wrap.checkout_payment_modal {
    .ant-modal-content {
      padding-right: 10px !important;
      padding-left: 10px !important;
    }
  }
}

// No scroll Css

.checkout_payment_modal {
  .wrap-top-modal {
    .body-modal {
      max-height: 100%;
    }
  }
}

.checkout_payment_modal {
  .ant-modal-content {
    .ant-modal-body {
      max-height: 100%;
    }
  }
}

// signup-info-wrap

.signup-info-wrap {
  .term-checkbox {
    margin-top: 20px !important;
    text-align: center;
  }

  .submit-btn-modal {
    .on-mobile {
      padding: 10px 20px !important;
      font-size: 16px !important;
    }
  }

  .term-checkbox span {
    color: rgba(0, 0, 0, 0.6) !important;
  }

  .term-checkbox span a {
    color: rgba(0, 0, 0, 0.6) !important;
    text-decoration: underline;
  }
}

@media screen and (max-width: 768px) {
  .signup-info-wrap {
    .login-modal-section {
      .login-modal-title {
        p {
          font-size: 20px;
          line-height: 1.5 !important;
        }
      }
    }

    .space-gpay {
      padding: 0px !important;
    }

    .login-with-google-btn {
      font-size: 16px !important;
      padding: 13px 15px !important;
    }

    .submit-btn-modal {
      .hl_cta_wrap {
        font-size: 16px;
        padding: 14px 20px;
      }
    }

    .term-checkbox {
      margin: 15px 0px;
    }

    .wrap-top-modal {
      .google-button-login {
        padding: 0px;
      }
    }

    .login-with-apple-btn {
      margin: 12px 0px;
    }

    .modal-login-form {
      input {
        height: 49px;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .auth-divider {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .signup-info-wrap {
    width: 415px !important;

    .login-modal-section {
      .login-modal-title {
        p {
          font-size: 18px !important;
        }
      }
    }

    .login-with-google-btn {
      font-size: 14px !important;
    }

    .submit-btn-modal {
      .hl_cta_wrap {
        font-size: 15px !important;
      }
    }

    .wrap-top-modal {
      padding: 0px;
    }

    .ant-modal-footer {
      margin-top: 0px;
    }

    .login-modal-section {
      .wrap-top-modal {
        .body-modal {
          padding: 20px 0px 0px !important;
        }
      }
    }

    .google-button-login {
      max-width: unset !important;
      width: 100% !important;
    }
  }

  .ant-modal.signup-info-wrap {
    max-width: calc(100vw - 50px);

    .ant-modal-content {
      padding-right: 10px !important;
      padding-left: 10px !important;
      padding-bottom: 25px !important;
    }
  }
}

// Dummy card css

.dummy-layout {
  font-family: "Arial", sans-serif;
  padding-top: 17px;
  padding-bottom: 17px;
  direction: ltr;

  .input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  }
  .theme_dir__rtl {
    .input-label {
      min-height: 50px;
    }
  }
  .input-label {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 2px;
    color: #01132c;
    line-height: 25px;
    @media (max-width: 768px) {
      font-size: 14px;
    }

    @media (max-width: 600px) {
      font-size: 12px;
    }
  }

  .input-field {
    height: 53px;
    font-size: 14px;
    border: 1px solid rgba(51, 58, 73, 0.2);
    border-radius: 10px;
    box-sizing: border-box;
    padding-left: 15px;
  }

  .expiry-cvv-section {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    .expiry-date,
    .cvv {
      display: flex;
      flex-direction: column;
      flex: 1;
      max-width: calc(50% - 5px);
    }
  }
}

// Process Bar SCSS

.dummy-layout {
  .input-group {
    position: relative;
    .input-field {
      padding-left: 3.3em !important;
    }
  }
  img {
    &.card-brand {
      position: absolute;
      z-index: 3;
      bottom: auto;
      left: 1.1em;
      display: none;
      width: 1.66em !important;
      height: 1.66em !important;
      top: 3em;
      display: block;
      object-fit: contain;
      object-position: center center;
    }
  }
  .cvv {
    position: relative;
  }
}
.tooltip-icon {
  position: absolute;
  z-index: 3;
  top: 3em;
  right: 0.8em;
  display: block;
  width: 16px;
  height: 16px;
  padding: 0.3em;
  cursor: pointer;
  outline: none;
  &:before {
    display: block;
    border-radius: 50%;
    background: rgba(1, 19, 44, 0.85);
    color: #fff;
    content: "i";
    font-size: 10px;
    font-weight: 700;
    line-height: 16px;
    opacity: 1;
    text-align: center;
    transition: 0.3s ease all;
  }
}
.payment_option_btn {
  display: flex;
  align-items: center;
  .defult-payment-button {
    &:last-child {
      margin-left: 5px;
    }
  }
}
.payment__mrg {
  margin-bottom: 15px;
}
.defult-payment-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 1.2;
  color: #ffffff;
  background-color: #000000;
  border-radius: 4px;
  padding: 13.5px 15px;
  text-align: center;
  text-transform: capitalize;
  box-sizing: border-box;
  cursor: pointer;
  img {
    margin-left: 8px;
    width: 41px;
    height: 100%;
  }
}

@media screen and (max-width: 450px) {
  .payment_option_btn {
    .defult-payment-button.dummy_apple-pay-button {
      padding: 13.5px 6px;
      font-size: 11px;
    }
    .defult-payment-button {
      img {
        margin-left: 5px;
        width: 28px;
      }
    }
  }
}

// Button Hide SCSS
.dummy-layout {
  .payment_option_btn {
    opacity: 0;
  }
}
.dummy-layout {
  .single_payment_btn {
    opacity: 0;
  }
}
