.dashboard-main-section {
  .container {
    .dashboard-box {
      box-shadow: 0 5px 100px rgba(0, 0, 0, 0.1);
      padding: 1rem;
      margin-bottom: 30px;
      border-radius: 1.6rem;
      background-color: #fff;
      box-sizing: border-box;
      margin-top: 30px;
      padding: 0 10px;
    }

    .dashboard-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 30px;

      .main-logo-img {
        img {
          display: block;

          @media screen and (max-width: 475px) {
            width: 130px;
            height: auto;
          }
        }
      }

      .setting-btn {
        display: flex;
        gap: 20px;

        @media screen and (max-width: 475px) {
          gap: 10px !important;
        }

        a {
          text-decoration: none;
        }

        button {
          align-items: center;
          border: none;
          background-color: #1664fa;
          color: #b08888;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 500;
          padding: 0.75rem 1.5rem;
          border-radius: 1.5rem;
          text-transform: capitalize;
          font-size: 0.85rem;
          letter-spacing: 1px;
          gap: 5px;
          cursor: pointer;

          @media screen and (max-width: 649px) {
            padding: 0.75rem 0.8rem !important;
          }

          @media screen and (max-width: 475px) {
            padding: 0.5rem 0.5rem !important;
          }

          svg {
            font-size: 20px;
          }

          @media screen and (max-width: 960px) {
            span {
              display: none;
            }

            .dashboard-main-section
              .container
              .dashboard-header
              .setting-btn
              .adminSide
              span {
              display: block !important;
            }
          }
        }
      }
      .hum-burger-menu {
        svg {
          font-size: 32px;
          cursor: pointer;
          fill: #c7c7c7;
          @media only screen and (max-width: 425px) {
            font-size: 28px;
          }
        }
      }
    }

    .dashboard-find-number {
      label {
        display: flex;
        align-items: center;
        margin-bottom: 1rem !important;
        font-size: 0.85rem;
        line-height: 1.3;
        letter-spacing: 1px;

        .dot {
          display: inline-block;
          background-color: #1664fa;
          width: 8px;
          height: 8px;
          border-radius: 100%;
        }

        span:last-child {
          margin-left: 1rem;
          font-weight: 500;
          color: #868686;
        }
      }

      .dashboard-locate {
        position: relative;

        input {
          height: 50px;
          width: 100%;
          padding-left: 75px;
          border-radius: 15px;
        }

        .flag-dropdown {
          border-radius: 15px 0 0 15px;
        }

        .selected-flag {
          padding: 0 40px 0 20px;
          border-radius: 15px 0 0 15px;
        }

        .input-suffix {
          background: #1664fa;
          width: 40px;
          display: flex;
          align-items: center;
          justify-content: space-around;
          font-size: 20px;
          border-radius: 12px;
          position: absolute;
          top: 5px;
          right: 5px;
          height: calc(100% - 10px);
        }

        svg {
          cursor: pointer;
          font-size: 20px !important;
          color: white !important;
        }
      }

      p:last-child {
        margin-bottom: 0;
      }
    }

    .dashboard-history {
      max-height: 400px;

      .dashboard-history-title {
        border-bottom: 1px solid;
        border-color: #f1f1f5;

        label {
          display: flex;
          align-items: center;
          margin-bottom: 1rem !important;
          text-transform: capitalize;
          font-size: 0.85rem;
          line-height: 1.3;
          letter-spacing: 1px;

          .dot {
            display: inline-block;
            background-color: #1664fa;
            width: 8px;
            height: 8px;
            border-radius: 100%;
          }

          span:last-child {
            margin-left: 1rem;
            font-weight: 500;
            color: #868686;
          }
        }
      }

      .dashboard-history-data {
        overflow: auto;
        max-height: 300px;

        @media only screen and (max-width: 767px) {
          font-size: 15px;
        }

        .items-scrollable {
          .addresspointer {
            cursor: pointer;
          }

          div {
            display: grid;
            grid-template-columns: auto 1fr auto;
            padding: 0.75rem 1rem;
            transition: background-color 0.25s ease-out;
            border-bottom: 1px solid;
            border-color: #f1f1f5;

            // ----------------------demo

            @media only screen and (max-width: 1200px) {
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 10px;
              flex-wrap: wrap;
            }
            .text-dark {
              color: #000 !important;
            }

            .address {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              padding: 0 1rem;
              color: #868686;

              // ----------------------demo

              @media only screen and (max-width: 1200px) {
                text-overflow: unset;
                overflow: unset;
                white-space: unset;
                padding: 0px;
              }
            }

            .pending {
              padding: 5px;
              background: #cdcdcda6;
              border-radius: 9px;
              font-size: 13px;
              font-weight: 600;
              letter-spacing: 0.5px;
            }

            .located {
              padding: 5px;
              background: #96ff94c7;
              border-radius: 9px;
              font-size: 13px;
              font-weight: 600;
              letter-spacing: 0.5px;
            }
          }
        }
      }
    }
  }
}
.dashboard-header-menu-mobile {
  min-width: 10% !important;
  .ant-dropdown-menu.ant-dropdown-menu-root {
    background-image: none !important;
    li {
      padding: 0;
    }
    a {
      align-items: center;
      color: #000 !important;
      display: flex;
      gap: 10px;
      padding: 5px 12px;
    }
    .active {
      color: white;
      background: rgb(60 126 255 / 38%);
      border-radius: 7px;
      width: 100%;
    }
  }
}
.login-modal-section {
  .login-modal-title {
    .span {
      font-size: 15px;
      opacity: 0.75;
      color: #fff;
    }

    p {
      direction: ltr;
      font-size: 18px;
      margin: 0;
      white-space: nowrap;
    }
  }

  .wrap-top-modal {
    .body-dash-modal {
      padding: 50px 25px 0;

      @media only screen and (max-width: 767px) {
        padding: 50px 0px 0;
      }

      .dashboard-find-number {
        label {
          display: flex;
          align-items: center;
          margin-bottom: 1rem !important;
          text-transform: capitalize;
          font-size: 0.85rem;
          line-height: 1.3;
          letter-spacing: 1px;

          @media screen and (max-width: 767px) {
            letter-spacing: 0.5px;
          }

          .dot {
            display: inline-block;
            background-color: #1664fa;
            width: 8px;
            height: 8px;
            flex: 0 0 8px;
            border-radius: 100%;
          }

          span:last-child {
            margin-left: 1rem;
            font-weight: 500;
            color: #868686;

            @media screen and (max-width: 767px) {
              margin-left: 0.6rem;
            }
          }
        }

        .dashboard-locate {
          position: relative;

          input {
            height: 50px;
            width: 100%;
            padding-left: 75px;
            border-radius: 15px;
          }

          .flag-dropdown {
            border-radius: 15px 0 0 15px;
          }

          .selected-flag {
            padding: 0 40px 0 20px;
            border-radius: 15px 0 0 15px;
          }
        }
      }

      .dashboard-message {
        label {
          display: flex;
          align-items: center;
          margin-top: 1rem !important;
          margin-bottom: 1rem !important;
          text-transform: capitalize;
          font-size: 0.85rem;
          line-height: 1.3;
          letter-spacing: 1px;

          @media screen and (max-width: 767px) {
            letter-spacing: 0.5px;
          }

          .dot {
            display: inline-block;
            background-color: #1664fa;
            width: 8px;
            height: 8px;
            border-radius: 100%;
          }

          span:last-child {
            margin-left: 1rem;
            font-weight: 500;
            color: #868686;

            @media screen and (max-width: 767px) {
              margin-left: 0.6rem;
            }
          }
        }

        textarea {
          height: 70px;
          width: 100%;
          max-width: calc(100% - 50px);
          border-radius: 15px;
          padding: 0.75rem 1.5rem;
          overflow: auto;
          resize: vertical;
          line-height: 1.3;
          font-size: 16px;
          &:focus {
            outline: none;
          }
        }
        .erroe_char-length {
          display: flex;
          justify-content: space-between;
          .message_error {
            color: red;
            margin: 0px;
            width: calc(100% + 250px);
          }
          .max_char_length {
            margin: 0;
            text-align: right;
            width: 100%;
          }
        }
      }

      .submit-btn-modal {
        width: 100%;
        margin-top: 30px;

        button {
          width: 100%;
          justify-content: center;
          align-items: center;
          font-weight: 500;
          padding: 0.75rem 1.5rem;
          border: none;
          border-radius: 1.5rem;
          text-transform: capitalize;
          font-size: 0.85rem;
          line-height: 1.3;
          letter-spacing: 1px;
          color: #fff;
          display: flex;
          width: 100%;
          background-color: #1664fa !important;
          cursor: pointer;
        }
      }
    }
  }
}

.billing-form {
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media only screen and (max-width: 767px) {
    gap: 15px;
  }

  label {
    color: #868686;
  }

  input {
    height: 40px;
    margin-top: 10px;
    color: #868686;
    font-weight: 500;

    @media only screen and (max-width: 767px) {
      margin-top: 5px;
    }
  }

  .ant-select {
    margin-top: 10px;

    @media only screen and (max-width: 767px) {
      margin-top: 5px;
    }

    .ant-select-selection-search {
      input {
        margin-top: 0;
      }
    }
  }

  .input-fx {
    display: flex;
    justify-content: space-between;

    .f-name-input {
      width: calc(50% - 10px);

      select {
        -webkit-appearance: none;
        width: 100%;
        background-color: #ffff;
        border: 1px solid #d9d9d9;
        padding: 10px 5px;
        border-radius: 5px;
        height: 40px;
        font-size: 14px;
        margin-top: 10px;
        color: #868686;
        font-weight: 500;

        &:focus {
          outline: none;
        }

        @media only screen and (max-width: 767px) {
          margin-top: 5px !important;
        }
      }
    }

    .l-name-input {
      width: calc(50% - 10px);
    }

    @media only screen and (max-width: 576px) {
      .f-name-input,
      .l-name-input {
        width: calc(50% - 5px);
      }
    }
  }

  .save-billing-btn {
    button {
      justify-content: center;
      align-items: center;
      font-weight: 500;
      padding: 0.75rem 1.5rem;
      border-radius: 1.5rem;
      text-transform: capitalize;
      font-size: 0.85rem;
      line-height: 1.3;
      letter-spacing: 1px;
      display: flex;
      width: 100%;
      flex: 0 0 100%;
      color: #fff;
      background-color: #1664fa !important;
      border: none;
      cursor: pointer;

      @media only screen and (max-width: 576px) {
        padding: 0.65rem 1.3rem;
      }

      @media only screen and (max-width: 420px) {
        padding: 0.55rem 1.2rem;
      }
    }
  }
}

.login-modal-section {
  padding: 10px !important;

  @media only screen and (max-width: 576px) {
    padding: 0 5px !important;
  }
}

@media only screen and (max-width: 576px) {
  .ant-modal .ant-modal-content {
    padding: 0 5px 5px !important;
  }
}
.header-lang-dropdown {
  max-width: 100%;
  width: fit-content;
  margin-left: auto;
  margin-right: 30px;
  ul{
    list-style: none;
    li {
      img {
        border-radius: 50%;
        object-fit: cover;
        width: 27px !important;
        height: 27px !important;
        cursor: pointer;
      }
    }
  }
}

.react-tel-input .country-list .search {
  padding: 10px 10px 6px 10px !important;

  input {
    padding: 5px 10px !important;
  }
}

.no-entries-message {
  font-weight: bold;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: transparent !important;
}

.dashboard-main-section
  .container
  .dashboard-find-number
  .dashboard-locate
  .selected-flag {
  padding: 0 75px 0 20px;
}

.dashboard-main-section
  .container
  .dashboard-find-number
  .dashboard-locate
  .flag-dropdown {
  background-color: transparent;
  z-index: 999;
}

.dashboard-main-section
  .container
  .dashboard-find-number
  .dashboard-locate
  input.desktop-input-nd {
  position: absolute;
  top: 0;
  bottom: auto;
  left: auto;
  right: 0;
  width: calc(100% - 50px);
  border-radius: unset;
  border: unset;
  background: transparent;
  padding-left: 60px;
  outline: unset;
  box-shadow: unset;
  display: block !important;
}

.dashboard-main-section
  .container
  .dashboard-find-number
  .dashboard-locate
  input {
  padding-left: 50px;
}

.location_modal.dsh_location_modal_popup
  .ant-modal-body
  .wrap-top-modal
  .body-dash-modal
  .react-tel-input {
  position: relative;
}

.location_modal.dsh_location_modal_popup
  .ant-modal-body
  .wrap-top-modal
  .body-dash-modal
  .react-tel-input
  input.form-control {
  padding-left: 50px;
  box-shadow: unset;
  outline: unset;
  z-index: 9;
}

.location_modal.dsh_location_modal_popup
  .ant-modal-body
  .wrap-top-modal
  .body-dash-modal
  .react-tel-input
  .flag-dropdown {
  background-color: transparent;
  border: unset;
  z-index: 9;
}

.location_modal.dsh_location_modal_popup
  .ant-modal-body
  .wrap-top-modal
  .body-dash-modal
  input.dsh_locating_input {
  position: absolute;
  top: 0;
  bottom: auto;
  left: auto;
  right: 0;
  width: calc(100% - 92px);
  border-radius: 0px 15px 15px 0px;
  padding-left: 15px;
  outline: unset;
  box-shadow: unset;
  display: block !important;
  z-index: 999;
}

.location_modal.dsh_location_modal_popup
  .ant-modal-body
  .wrap-top-modal
  .body-dash-modal
  input.dsh_locating_input:focus,
.location_modal.dsh_location_modal_popup
  .ant-modal-body
  .wrap-top-modal
  .body-dash-modal
  input.dsh_locating_input:hover {
  outline: unset;
  box-shadow: unset;
  border-color: #cacaca;
}

.location_modal.dsh_location_modal_popup
  .ant-modal-body
  .wrap-top-modal
  .body-dash-modal
  .input-suffix {
  display: none;
}

.location_modal.dsh_location_modal_popup
  .ant-modal-body
  .wrap-top-modal
  .body-dash-modal
  .react-tel-input
  .flag-dropdown
  .selected-flag {
  padding: 0px 70px 0 20px;
}

.location_modal.dsh_location_modal_popup
  .react-tel-input
  .flag-dropdown.open
  .selected-flag {
  background-color: transparent;
}

.react-tel-input {
  .selected-flag {
    &:hover {
      background-color: transparent !important;
    }
    &:focus {
      background-color: transparent !important;
    }
  }
}
.dashboard-main-section {
  .container {
    .dashboard-find-number {
      .dashboard-locate {
        .selected-flag {
          padding: 0 75px 0 20px !important;
        }
        .flag-dropdown {
          background-color: transparent;
        }
        input.desktop-input-nd {
          position: absolute;
          top: 0;
          bottom: auto;
          left: auto;
          right: 0;
          width: calc(100% - 50px);
          border-radius: unset;
          border: unset;
          background: transparent;
          padding-left: 60px;
          outline: unset;
          box-shadow: unset;
          display: block !important;
        }
        input {
          padding-left: 55px;
        }
      }
    }
  }
}
.location_modal.dsh_location_modal_popup {
  .ant-modal-body {
    .wrap-top-modal {
      .body-dash-modal {
        .react-tel-input {
          position: relative;
          input.form-control {
            padding-left: 50px;
            box-shadow: unset;
            outline: unset;
            z-index: 9;
          }
          .flag-dropdown {
            background-color: transparent;
            border: unset;
            z-index: 9;
            .selected-flag {
              padding: 0px 70px 0 20px;
              cursor: pointer;
            }
          }
        }
        input.dsh_locating_input {
          position: absolute;
          top: 0;
          bottom: auto;
          left: auto;
          right: 0;
          width: calc(100% - 92px);
          border-radius: 0px 15px 15px 0px;
          padding-left: 15px;
          outline: unset;
          box-shadow: unset;
          display: block !important;
          z-index: 999;
          &:focus {
            outline: unset;
            box-shadow: unset;
            border-color: #cacaca;
          }
          &:hover {
            outline: unset;
            box-shadow: unset;
            border-color: #cacaca;
          }
        }
        .input-suffix {
          display: none;
        }
      }
    }
  }
  .react-tel-input {
    .flag-dropdown.open {
      .selected-flag {
        background-color: transparent;
      }
    }
  }
}

//Dashboard RTL Find Number SCSS

.theme_dir__rtl {
  .dashboard-main-section {
    .container {
      .dashboard-find-number {
        .dashboard-locate {
          input.desktop-input-nd {
            padding-right: 60px;
            padding-left: 0px;
            left: 0;
            right: auto;
          }
          .flag-dropdown {
            left: auto;
            right: 0px;
          }
          input {
            padding-right: 55px !important;
          }
          .selected-flag {
            padding: 0 78px 0 20px !important;
          }
        }
      }
    }
    .dashboard-find-number {
      .react-tel-input {
        .selected-flag {
          .arrow {
            left: -20px;
          }
          .flag {
            right: 20px;
          }
        }
      }
    }
  }
}
.dashboard-main-section {
  .container {
    .dashboard-find-number {
      .dashboard-locate {
        .selected-flag {
          background-color: transparent;
        }
      }
    }
  }
}

.theme_dir__rtl {
  .login-modal-section {
    .wrap-top-modal {
      .body-dash-modal {
        .dashboard-find-number {
          .dashboard-locate {
            .flag-dropdown {
              z-index: 9999;
            }
            input {
              padding-left: 80px;
              padding-right: 10px;
              left: 0;
              right: auto;
              border-radius: 15px 0px 0px 15px;
            }
            .dsh_locating_popup {
              input {
                border-radius: 15px 15px 15px 15px;
                padding-right: 45px;
              }
            }
          }
        }
      }
    }
  }
}
.theme_dir__rtl.location_modal.dsh_location_modal_popup {
  .ant-modal-body {
    .wrap-top-modal {
      .body-dash-modal {
        .react-tel-input {
          .flag-dropdown {
            .selected-flag {
              padding: 0 20px 0 20px;
            }
          }
        }
      }
    }
  }
}

.dashboard-main-section {
  .container {
    .dashboard-find-number {
      .dashboard-locate {
        input.desktop-input-nd {
          &:-webkit-autofill {
            transition: background-color 9999s ease-in-out 0s;
            &:hover {
              transition: background-color 9999s ease-in-out 0s;
            }
            &:focus {
              transition: background-color 9999s ease-in-out 0s;
            }
            &:active {
              transition: background-color 9999s ease-in-out 0s;
            }
          }
        }
      }
    }
  }
}

//  Input Ellipsis Css

.dashboard-main-section {
  .container {
    .dashboard-find-number {
      .dashboard-locate {
        input.desktop-input-nd {
          padding-right: 45px;
        }
      }
    }
  }
}
.theme_dir__rtl {
  .dashboard-main-section {
    .container {
      .dashboard-find-number {
        .dashboard-locate {
          input.search-box {
            padding: 5px 10px !important;
          }
        }
      }
    }
  }
}

.input_ltr {
  // text-align: right;
  direction: ltr;
}

.dsh_location_modal_popup .react-tel-input :disabled {
  cursor: pointer;
}

.theme_dir__rtl {
  .dsh_location_modal_popup {
    .react-tel-input {
      .selected-flag {
        .arrow {
          left: 8px;
        }
      }
    }
  }

  .login-modal-section {
    .wrap-top-modal {
      .body-dash-modal {
        .dashboard-find-number {
          .dashboard-locate {
            .dsh_locating_popup {
              input {
                padding-right: 20px;
              }
            }
          }
        }
      }
    }
  }

  .login-modal-section.dsh_location_modal_popup {
    .wrap-top-modal {
      .body-dash-modal {
        .dashboard-find-number {
          .dashboard-locate {
            .selected-flag {
              padding: 0px 65px 0 20px;
            }
          }
        }
      }
    }
  }
}

.dashboard-main-section {
  .container {
    .dashboard-history {
      .dashboard-history-data {
        .items-scrollable {
          div {
            .text-dark {
              direction: ltr;
            }
          }
        }
      }
    }
  }
}
.dashboard-header-menu-mobile,
.dashboard-header-menu-mobile * {
  box-sizing: border-box;
}

.dsh_location_modal_popup {
  .ant-modal-content {
    .ant-modal-body {
      padding-top: 20px !important;
    }
  }
}