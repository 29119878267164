.bg-grad-section {
  padding-bottom: 150px;
  background-color: rgb(218, 215, 215);
  background-image: linear-gradient(#ffffff, #f7f6f9);

  .Locate-Any-Phone {
    background-image: url("../../assets/home/locate-any-phone-bg.webp");
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;

    .wid-home-banner {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-around;
      padding: 150px 50px 0;
      align-items: center;

      @media only screen and (max-width: 768px) {
        height: 100%;
        min-height: 100vh;
      }

      .df-first-section {
        display: flex;
        max-width: 1200px;
        width: 100%;
        align-items: center;
        margin: 0 auto;
        justify-content: space-between;

        @media only screen and (max-width: 768px) {
          margin: auto auto;
          height: max-content;
        }

        .right-contant {
          width: 50%;
          display: flex;
          justify-content: space-around;
          align-items: center;

          .banner-img {
            position: relative;

            img {
              width: 540px;
              height: 100%;
            }

            .location-banner-img-text {
              position: absolute;
              z-index: 1;
              top: 53%;
              left: 40%;
              font-size: 18px;
              font-weight: 700;
              color: #1664fa;
              height: max-content;
              width: max-content;
              margin: 0;
              rotate: 14deg;
              @media only screen and (max-width: 1175px) {
                font-size: 16px;
              }
              @media only screen and (max-width: 1028px) {
                top: 53%;
                left: 40%;
                font-size: 13px;
              }
            }

            .location-banner-img-desc {
              position: absolute;
              z-index: 1;
              top: 60%;
              left: 16%;
              font-size: 17px;
              font-weight: 500;
              color: black;
              width: 310px;
              height: max-content;
              margin: 0;
              rotate: 14deg;

              @media only screen and (max-width: 1028px) {
                top: 59%;
                left: 17%;
                font-size: 14px;
                width: 205px;
              }
            }

            .location-banner-img-view {
              position: absolute;
              z-index: 1;
              top: 69%;
              right: 24%;
              font-size: 14px;
              font-weight: 500;
              color: #1664fa;
              width: max-content;
              height: max-content;
              margin: 0;
              text-align: right;
              width: 300px;
              rotate: 15deg;

              @media only screen and (max-width: 1028px) {
                top: 67%;
                right: 23%;
                font-size: 12px;
              }
            }
          }
        }

        .left-contant {
          width: 50%;
          margin-top: -100px;

          .right-text {
            margin-bottom: 20px;
            color: #fff;

            p {
              margin: 0;
            }
          }

          .head-title {
            font-family: "GT Walsheim Pro";
            font-style: normal;
            font-weight: 600;
            font-size: 56px;
            margin-top: 40px;
            line-height: 1.2;
          }

          .head-semi-title {
            font-family: "GT Walsheim Pro";
            font-style: normal;
            font-weight: 300;
            font-size: 21px;
            line-height: 30px;
          }

          .wrap-right-input-error {
            position: relative;

            p {
              margin: -16px -3px 10px 0px;
              color: white;
              position: absolute;
              background: #ff3855;
              width: max-content;
              padding: 6px 15px;
              top: -10px;
              border-radius: 8px;
              left: 50%;
              box-shadow: 0 4px 5px 2px #fd8a97;
              transform: translateX(-50%);
              line-height: 1.3;
              display: flex;
              align-items: center;
              gap: 10px;
              z-index: 1;
            }
          }

          .head-input {
            display: flex;
            position: relative;
            background: white;
            border-radius: 50px;

            .mobile-input {
              display: none;
            }

            .mobile-desktop {
              display: block;
            }

            .desktop-input,
            .mobile-input {
              border-radius: 50px 0 0 50px;
              background-color: white;
              width: 100px;

              .flag-dropdown {
                width: 100%;

                .selected-flag {
                  width: 100%;
                  padding: 0px 0px 0px 12px;
                  cursor: pointer;
                  background: transparent !important;
                }
              }
            }

            .react-tel-input {
              color: black;

              input {
                padding-left: 44px;
                background-color: white;
                border: none;
              }

              .flag-dropdown {
                padding: 0 5px;
                background: transparent;
                border: none;
              }
            }
            .three_city_dial {
              width: 110px;
              input {
                padding-left: 48px;
              }
            }
            .big_city_dial {
              width: 110px;
              input {
                padding-left: 48px;
              }
            }
            .medium_city_dial {
              input {
                padding-left: 50px;
              }
            }
            .small_city_dial {
              width: 90px;
              input {
                padding-left: 52px;
              }
              .flag-dropdown {
                .selected-flag {
                  padding: 0px 0px 0px 14px;
                }
              }
            }

            .desk {
              display: block;
              background: transparent;
              border: none;
              margin: auto;
              height: 80%;
              border-left: 1px solid gray;

              &:focus {
                outline: none;
                box-shadow: none;
              }
            }

            .mobile {
              display: none;
              background: transparent;
              border: none;
              height: 80%;
              margin: auto;
              border-left: 1px solid gray;

              &:focus {
                outline: none;
                box-shadow: none;
              }
            }

            .desktop-input-nd {
              border-radius: 0 50px 50px 0;

              @media only screen and (min-width: 1025px) and (max-width: 1220px) {
                &::placeholder {
                  width: calc(100% - 166px) !important;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }

              @media only screen and (min-width: 768px) and (max-width: 945px) {
                &::placeholder {
                  width: calc(100% - 166px) !important;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }

              @media only screen and (max-width: 380px) {
                &::placeholder {
                  width: calc(100% - 44px) !important;
                }
              }
            }

            input {
              height: 50px;
              width: 100%;
              border-radius: 50px 0 0 50px;
            }

            .input-suffix {
              background-color: white;
              display: flex;
              align-items: center;
              justify-content: space-around;
              font-size: 20px;
              border-radius: 50px;
              position: absolute;
              top: 0px;
              right: 0px;
              padding: 0 17px;
              gap: 10px;
              height: 100%;
            }

            svg {
              cursor: pointer;
              font-size: 20px;
            }
          }
        }
      }
    }
  }

  .home-special-offer {
    margin-top: 70px;
    padding: 70px;

    @media only screen and (max-width: 991px) {
      padding: 70px 30px;
    }

    .specialOffer-container {
      max-width: 1200px;
      width: 100%;
      margin: 0 auto;

      .special-offerbox-partition {
        display: flex;
        gap: 30px;

        .right-content {
          width: 66%;
          display: flex;
          flex-wrap: wrap;
          gap: 30px;

          @media only screen and (max-width: 1199px) {
            width: 100%;
          }

          .pricing-title {
            width: 100%;
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
          }
        }

        .bottom-btn {
          position: absolute;
          bottom: 30px;
          left: 30px;
          display: flex;
          width: calc(100% - 60px);
          height: 50px;

          a {
            width: 100%;
            text-decoration: none;
          }
        }

        .desc-special-span {
          color: inherit;
          margin-top: 30px;

          p {
            margin: 0;
          }
        }

        .desc-special {
          margin-top: 25px;
          color: inherit;

          h3 {
            font-size: 44px;
            margin: 0;
            font-weight: 700;
            display: flex;
            align-items: center;

            @media only screen and (max-width: 1024px) {
              margin-bottom: 8px;
            }
          }
        }

        .box-heading {
          width: max-content;
          padding: 0 15px;
          border-radius: 20px;

          p {
            padding: 4px;
            margin: 0;
          }
        }

        .img-box-2,
        .img-box-3 {
          width: calc(50% - 75px);
          border-radius: 30px;
          height: auto;
          padding: 25px 30px;
          position: relative;

          @media only screen and (max-width: 1199px) {
            padding-bottom: 100px;
            width: calc(50% - 75px);
          }
        }

        .img-box-2 {
          background-color: #ecedef;
          color: #333333;

          .box-heading {
            color: white;
            background-color: #333333;
          }

          .bottom-btn {
            .hl_cta_wrap {
              position: relative;
              overflow: hidden;
              width: 100%;
              height: 100%;
              padding: 12px 20px;
              border: 1px solid currentColor;
              border-radius: 14px;
              transition: 0.5s ease-in-out;
              z-index: 1;
              color: #1664fa;
              font-weight: 700;
              font-size: 20px;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;

              &:hover {
                color: #fff !important;
              }
            }
          }
        }

        .img-box-3 {
          background-color: #1664fa;
          color: #fff;

          .box-heading {
            color: #1664fa;
            background-color: #ffffff;
          }

          .bottom-btn {
            .hl_cta_wrap-white {
              position: relative;
              overflow: hidden;
              width: 100%;
              height: 100%;
              padding: 12px 20px;
              border: 1px solid currentColor;
              border-radius: 14px;
              border-color: #fff;
              transition: 0.5s ease-in-out;
              z-index: 1;
              background-color: #1664fa;
              color: #fff;
              font-weight: 700;
              font-size: 20px;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }

        .img-box {
          position: relative;
          background-color: #171c1c;
          height: auto;
          padding: 25px 30px;
          padding-bottom: 0;
          border-radius: 30px;
          width: 33%;
          overflow: hidden;
          color: white;

          .main-title {
            width: unset;
          }

          p {
            margin: 0;
            padding: 0;
          }

          .box-heading {
            font-weight: 700;
            padding: 0;
            font-size: 55px;
            margin-bottom: 20px;

            @media only screen and (max-width: 1500px) {
              font-size: 45px;
            }
          }

          .desc-special {
            font-weight: 600;
            font-size: 20px;
            margin-bottom: 20px;
          }

          .desc-special-span {
            font-weight: 400;
            font-size: 17px;
            width: 50%;
          }

          .backGroundImg2 {
            margin-top: -25px;
            width: 100%;
            display: flex;
            justify-content: space-around;
          }

          @media screen and (max-width: 1199px) {
            .backGroundImg2 {
              flex-direction: row-reverse;
              justify-content: normal !important;
              margin-top: -140px;
              position: relative;
              z-index: 0;

              img {
                max-width: 100%;
              }
            }
          }

          @media screen and (max-width: 1024px) {
            .backGroundImg2 {
              margin-top: -100px;
            }
          }

          @media screen and (max-width: 1199px) {
            .backGroundImg2 {
              margin-top: 30px;
            }
          }
        }
      }
    }
  }

  .how-work-section {
    padding: 0 50px;

    .how-work-container {
      margin: 0 auto;
      max-width: 1200px;
      width: 100%;

      .how-work-title {
        text-align: center;
        font-style: normal;
        font-weight: 700;
        font-size: 55px;
        line-height: 1.2;
        margin: 70px 0 50px;

        p {
          margin: 0;
        }
      }

      .how-work-body {
        .how-work-desc-sections {
          display: flex;
          gap: 30px;
          justify-content: space-around;

          @media only screen and (max-width: 1299px) {
            flex-wrap: wrap;
          }

          .box {
            width: calc(33.333% - 10px);
            position: relative;
            overflow: hidden;
            border-radius: 60px 0 60px 60px;

            @media only screen and (max-width: 1299px) {
              width: calc(48% - 10px);
            }

            @media only screen and (max-width: 875px) {
              width: 100%;
            }

            .bgboximg {
              position: absolute;
              width: 100%;
              height: 100%;
              min-height: auto;
              object-fit: cover;
              object-position: top right;
            }

            p {
              margin: 0;
            }

            .how-work-bg {
              position: absolute;
              width: 100%;
              z-index: 0;
            }

            .how-work-contant {
              width: 100%;
              display: flex;
              justify-content: space-between;
              position: relative;

              .left-contant-count {
                position: absolute;
                right: 10px;
                width: 3.5vw;
                height: 3.5vw;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #1664fa;
                border-radius: 50%;
                top: 10px;
                font-weight: 900;
                font-size: 36px;
                color: white;
              }

              .how-work-svg {
                margin-bottom: 15px;
              }

              .left-contant {
                width: calc(100% - 78px);
                padding-left: 40px;
                padding-top: 40px;
                padding-bottom: 40px;

                .how-work-box-title {
                  margin-bottom: 15px;
                  font-style: normal;
                  font-weight: 800;
                  font-size: 24px;
                  line-height: 29px;
                }

                .how-work-desc {
                  font-style: normal;
                  font-weight: 400;
                  font-size: 17px;
                  line-height: 26px;
                }
              }
            }

            .right-contant {
              width: 25%;
              position: relative;

              .cir-cont {
                background: #1664fa;
                color: white;
                font-style: normal;
                width: 90%;
                font-weight: 900;
                font-size: 38px;
                text-align: center;
                border-radius: 100%;
                position: absolute;
                right: 0;

                h1 {
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

.choose-tracelo-section {
  background-color: white;
  padding: 75px 50px;

  .choose-tracelo-container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;

    .wrap-choose-tracelo {
      .choose-tracelo-title {
        text-align: center;

        p {
          margin: 0;
          font-style: normal;
          font-weight: 700;
          font-size: 55px;
          line-height: 66px;
        }
      }

      .choose-tracelo-desc {
        text-align: center;
        margin-top: 20px;
        margin-bottom: 50px;

        p {
          margin: 0;
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 24px;
        }
      }

      .choose-tracelo-under-section {
        margin-top: 50px;

        .df-wrap-service {
          display: flex;
          gap: 30px;

          .choose-tracelo-box {
            width: calc(25% - 10px);

            .choose-tracelo-part {
              display: flex;
              flex-direction: column;

              .choose-tracelo-box-title {
                margin-top: 20px;

                p {
                  margin: 0;
                  font-style: normal;
                  font-weight: 800;
                  font-size: 20px;
                }
              }

              .choose-tracelo-box-desc {
                margin-top: 20px;

                p {
                  margin: 0;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 17px;
                }
              }
            }
          }
        }
      }

      .locate-btn {
        margin-top: 50px;
        display: flex;
        align-items: center;
        justify-content: space-around;

        a {
          text-decoration: none;
        }

        .hl_cta_wrap {
          position: relative;
          overflow: hidden;
          width: max-content;
          height: 100%;
          padding: 12px 20px;
          border: 1px solid currentColor;
          border-radius: 14px;
          transition: 0.5s ease-in-out;
          z-index: 1;
          background-color: transparent;
          color: #1664fa;
          font-weight: 700;
          font-size: 20px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            color: #fff !important;
          }
        }
      }
    }
  }
}

.bg-grd-section {
  padding: 100px 30px;
  background-color: rgb(218, 215, 215);
  background-image: linear-gradient(#f7f6f9, #ffffff);

  .frequently-asked-question {
    .question-title {
      text-align: center;
      margin-bottom: 70px;

      p {
        margin: 0;
        font-style: normal;
        font-weight: 700;
        font-size: 55px;
        line-height: 66px;
        margin-bottom: 20px;
      }

      span {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
      }
    }

    .question-collapse {
      max-width: 1000px;
      margin: 0 auto;
      width: 100%;

      .collapse-box {
        background: white;
        padding: 20px 25px;
        border: 0.5px solid;
        border-color: #e3e3e3;
        border-radius: 12px;
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }

        cursor: pointer;

        .collapse-box-df-title {
          display: flex;
          justify-content: space-between;

          .collapse-box-title {
            p {
              margin: 0;
              font-weight: 600;
              font-size: 20px;
              line-height: 24px;
            }
          }
        }

        .open-tab-desc {
          width: 80%;
          margin-top: 20px;

          p {
            margin: 0;
            font-weight: 400;
            font-size: 17px;
            line-height: 26px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1440px) {
}

@media only screen and (max-width: 1199px) {
  .img-box-3,
  .img-box-2 {
    padding-bottom: 100px !important;
    width: calc(50% - 75px) !important;

    .desc-special {
      gap: 12px !important;

      h3 {
        font-size: 35px !important;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .bg-grad-section {
    .Locate-Any-Phone {
      .wid-home-banner {
        padding: 149px 20px 0;
      }

      .wid-home-banner .df-first-section {
        .left-contant {
          padding-bottom: 50px;
          width: 100%;
          margin-top: 0;
        }

        .right-contant {
          img {
            width: 348px !important;
          }
        }
      }
    }
  }

  .desc-special {
    gap: 0 !important;
    align-items: flex-start !important;
    flex-direction: column;

    p {
      margin: 0;
    }
  }

  .df-wrap-service {
    flex-wrap: wrap;

    .choose-tracelo-box {
      width: calc(50% - 15px) !important;
    }
  }

  .bg-grad-section {
    padding-bottom: 70px !important;
  }
}

@media only screen and (max-width: 991px) {
  .special-offerbox-partition {
    flex-wrap: wrap;
    justify-content: space-around;

    .img-box-3,
    .img-box-2 {
      .desc-special-span {
        margin-bottom: 75px;
      }
    }
  }

  .collapse-box-df-title {
    gap: 50px;

    .collapse-box-title {
      p {
        font-size: 18px !important;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .desk {
    display: none !important;
  }

  .mobile {
    display: block !important;
  }

  .bg-grad-section {
    .Locate-Any-Phone {
      .wid-home-banner {
        padding: 20% 10% 15%;
      }
    }
  }

  .bg-grad-section {
    .Locate-Any-Phone .wid-home-banner .df-first-section {
      .left-contant {
        width: 100%;

        .head-title {
          width: 220px;
          font-size: 38px;
          line-height: 1.2;
        }

        .heading-title {
          width: 300px;
          font-size: 38px;
          line-height: 1.2;
        }

        .head-semi-title {
          font-size: 18px;
          line-height: 25px;
        }

        .head-input .react-tel-input input {
          width: 90px;
        }
      }

      .right-contant {
        display: none !important;
      }
    }

    .home-special-offer .specialOffer-container {
      .special-offerbox-partition {
        .img-box,
        .img-box-2,
        .img-box-3 {
          width: 100% !important;
        }

        .img-box .box-heading {
          font-size: 25px;
        }
      }
    }

    .question-title {
      p {
        font-size: 45px !important;
      }
    }

    .how-work-section {
      padding: 0 20px;

      .how-work-container {
        .how-work-title {
          font-size: 35px;
        }

        .how-work-body .how-work-desc-sections {
          flex-wrap: wrap;
          justify-content: space-around;
          max-width: 550px;
          margin: 0px auto;

          .box {
            width: 100% !important;
            max-width: 100%;

            .how-work-contant .left-contant {
              width: 100% !important;
              padding: 30px 30px 30px !important;
              min-height: 192px;
            }

            img {
              width: 50px;
              height: 50px;
            }

            .how-work-box-title {
              font-size: 22px !important;
              line-height: 22px !important;
            }

            .how-work-desc {
              font-size: 17px !important;
              line-height: 22px !important;
            }
          }
        }
      }
    }

    .input-suffix {
      .span-text {
        display: none;
      }
    }
  }

  .bg-grad-section
    .Locate-Any-Phone
    .wid-home-banner
    .df-first-section
    .left-contant
    .head-input
    .input-suffix {
    -webkit-appearance: none;
    top: 0;
    bottom: 0;
    height: calc(100% - 6px);
    margin: 3px 0 3px;
    right: 3px;
    aspect-ratio: 1;
    background: #1664fa;
    color: #fff;
    padding: 8px;
    border-radius: 50px;

    svg {
      flex: 0 0 23px;
      height: 23px;
    }
  }

  .choose-tracelo-section {
    padding: 70px 20px;

    .choose-tracelo-title {
      padding: 0;
      font-size: 42px !important;
      line-height: 1.2px !important;
    }

    .choose-tracelo-container .choose-tracelo-tag {
      max-width: 80px;
      grid-row: 1/-1;

      img {
        max-width: 100%;
        height: auto;
        vertical-align: top;
        width: auto;
      }
    }

    .df-wrap-service {
      flex-wrap: wrap;
      gap: 15px !important;

      .choose-tracelo-box {
        width: 100% !important;
        border-bottom: 0.5px solid;
        padding-bottom: 15px;
        border-color: #d7d7d7;

        &:last-child {
          border-bottom: 0;
          padding-bottom: 0;
        }
      }
    }

    .choose-tracelo-container
      .wrap-choose-tracelo
      .choose-tracelo-under-section
      .df-wrap-service
      .choose-tracelo-box
      .choose-tracelo-part {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-template-rows: auto auto;
      column-gap: 12px;

      .choose-tracelo-box-title {
        margin-top: 5px;
      }

      .choose-tracelo-box-desc {
        margin-top: 8px;
      }
    }
  }

  .bg-grd-section {
    padding: 70px 20px;

    .frequently-asked-question {
      font-size: 40px !important;

      .question-title {
        margin-bottom: 30px;

        p {
          font-size: 35px;
          line-height: 1.2;
          margin: 0 0 15px;
        }

        span {
          font-size: 18px;
          line-height: 1.2;
          display: block;
        }
      }

      .question-collapse .collapse-box {
        .collapse-box-icon {
          flex: 0 0 18px;

          img {
            max-width: 100%;
            height: auto;
            display: block;
          }
        }

        .collapse-box-df-title {
          align-items: center;
          gap: 20px;
        }
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .bg-grad-section .Locate-Any-Phone {
    .wid-home-banner {
      .df-first-section {
        .left-contant {
          .ant-space {
            width: 100% !important;
          }

          .head-input .react-tel-input {
            width: 80px;
            flex: 0 0 80px;

            input {
              padding-left: 40px;
              width: 80px;
            }
          }
        }

        .right-contant {
          img {
            width: 390px !important;
          }
        }
      }
    }
  }

  .how-work-desc-sections {
    .group-box-1,
    .group-box-2,
    .group-box-3 {
      width: 100% !important;
    }
  }

  .bg-grad-section {
    .home-special-offer {
      .specialOffer-container {
        .special-offerbox-partition {
          .img-box {
            padding: 25px 15px 0px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .bg-grad-section .home-special-offer {
    padding: 20px !important;

    .specialOffer-container .special-offerbox-partition {
      .img-box {
        .desc-special {
          font-size: 16px;
        }

        .desc-special-span {
          margin-top: 20px !important;
          margin-bottom: 30px;
        }
      }

      .img-box-2,
      .img-box-3 {
        .desc-special {
          margin-top: 17px !important;

          h3 {
            font-size: 35px !important;
          }

          p {
            margin: 0;
          }
        }

        .desc-special-span {
          margin-top: 20px !important;
          margin-bottom: 30px;
        }
      }
    }
  }

  .collapse-box {
    .open-tab-desc {
      width: 100% !important;
    }
  }

  .mobile-input {
    display: block !important;
  }

  .desktop-input {
    display: none !important;
  }

  .img-box {
    .backGroundImg2 {
      img {
        height: 100%;
        margin: 0 auto;
      }
    }
  }

  .bg-grad-section {
    .Locate-Any-Phone .wid-home-banner .df-first-section {
      .left-contant {
        width: 100%;

        .heading-title {
          width: 300px;
          font-size: 33px;
          line-height: 1.2;
        }
      }
    }
  }
}

@media only screen and (max-width: 475px) {
  .bg-grad-section {
    .Locate-Any-Phone {
      .wid-home-banner {
        padding: 20% 4% 15%;
      }
    }
  }
}

.billing-form {
  .ant-select {
    span.ant-select-selection-item {
      color: #868686;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .bg-grad-section {
    .how-work-section {
      .how-work-container {
        .how-work-title {
          font-size: 26px;
        }
      }
    }
  }
}

// Common Footer Css

.footer {
  .footer-container {
    .footer-section {
      .footer-inner-container {
        .df-footer {
          .footer-right-contan {
            .df-right-footer-menu {
              .legal-info {
                margin-right: 10px;
              }

              .legal-menus {
                ul {
                  li {
                    &:not(:last-child) {
                      margin-bottom: 12px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .footer {
    .footer-container {
      .footer-section {
        .footer-inner-container {
          .footer-bottom-contant {
            .footer-bottom-flex {
              flex-wrap: wrap;

              .tracelo-footer {
                width: 100%;
                text-align: center;
                margin-bottom: 10px;
              }

              .policies-footer {
                width: 100%;
                text-align: center;
                justify-content: center;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .footer {
    .footer-container {
      .footer-section {
        .footer-inner-container {
          .footer-bottom-contant {
            .footer-bottom-flex {
              .policies-footer {
                flex-wrap: wrap;

                > div {
                  width: 100%;
                  margin-bottom: 8px;
                }

                .tems-policy {
                  margin-bottom: 0px;
                }

                .cookies-policy {
                  p {
                    &::after {
                      all: unset !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.ant-modal {
  .ant-modal-close {
    right: 5%;
  }
}

@media only screen and (max-width: 1099px) {
  .bg-grad-section {
    .Locate-Any-Phone {
      .wid-home-banner {
        .df-first-section {
          .right-contant {
            .banner-img {
              width: 100%;

              img {
                width: 100%;
                max-width: 540px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1199px) {
  .header-main-section {
    .header {
      .header-df {
        .header-menu-nd-login {
          .header-menu {
            li {
              a {
                font-size: 16px;
              }
            }
          }

          .header-login-btn {
            .hl_cta_wrap {
              padding: 10px 10px;
            }

            margin-left: 10px;
          }

          .header-lang-drop {
            margin-left: 10px;
          }

          .responsive-header-btn {
            margin-left: 10px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 350px) {
  .collapse-box-df-title {
    .collapse-box-title {
      p {
        max-width: 170px;
      }
    }
  }
}

.bg-grad-section {
  .Locate-Any-Phone {
    .wid-home-banner {
      .df-first-section {
        .left-contant {
          .head-input {
            .react-tel-input {
              .country-list {
                .search {
                  input {
                    &.search-box {
                      padding: 5px 10px !important;
                      border-radius: 7px !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1028px) {
  .theme_dir__rtl {
    .bg-grad-section {
      .Locate-Any-Phone {
        .wid-home-banner {
          .df-first-section {
            .right-contant {
              .banner-img {
                .location-banner-img-view {
                  top: 68%;
                  width: 100%;
                  max-width: 235px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.theme_dir__rtl {
  .bg-grad-section {
    .Locate-Any-Phone {
      .wid-home-banner {
        .df-first-section {
          .right-contant {
            .banner-img {
              .location-banner-img-view {
                text-align: left;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .theme_dir__rtl {
    .bg-grad-section {
      .Locate-Any-Phone {
        .wid-home-banner {
          .df-first-section {
            .right-contant {
              .banner-img {
                .location-banner-img-view {
                  max-width: 225px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 920px) {
  .homePage-main-section {
    .Locate-Any-Phone {
      .wid-home-banner {
        .df-first-section {
          .left-contant {
            .head-input {
              .big_city_dial {
                width: 120px;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .homePage-main-section {
    .Locate-Any-Phone {
      .wid-home-banner {
        .df-first-section {
          .left-contant {
            .head-input {
              .react-tel-input {
                input {
                  width: 110px;
                  padding-left: 44px;
                  font-size: 13px;
                }
              }
              .big_city_dial {
                width: 110px;
                input {
                  padding-left: 48px;
                }
              }
              .three_city_dial {
                width: 90px;
                input {
                  padding-left: 48px;
                }
              }
              .medium_city_dial {
                input {
                  width: 90px;
                  padding-left: 50px;
                }
              }
              .small_city_dial {
                width: 80px;
                input {
                  padding-left: 52px;
                }
                .flag-dropdown {
                  .selected-flag {
                    padding: 0px 0px 0px 14px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
