// Dummy card css

.reactive-dummy-layout {
  font-family: "Arial", sans-serif;
  padding-top: 17px;
  padding-bottom: 17px;

  .reactive-input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  }

  .reactive-input-label {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 2px;
    color: #01132c;
  }

  .reactive-input-field {
    height: 53px;
    font-size: 14px;
    border: 1px solid rgba(51, 58, 73, 0.2);
    border-radius: 10px;
    box-sizing: border-box;
    padding-left: 15px;
  }

  .reactive-expiry-cvv-section {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    .reactive-expiry-date,
    .reactive-cvv {
      display: flex;
      flex-direction: column;
      flex: 1;
      max-width: calc(50% - 5px);
    }
  }
}

// Process Bar SCSS

.reactive-dummy-layout {
  .reactive-input-group {
    position: relative;
    .reactive-input-field {
      padding-left: 3.3em !important;
    }
  }
  img {
    &.reactive-card-brand {
      position: absolute;
      z-index: 3;
      bottom: auto;
      left: 1.1em;
      display: none;
      width: 1.66em !important;
      height: 1.66em !important;
      top: 3em;
      display: block;
      object-fit: contain;
      object-position: center center;
    }
  }
  .reactive-cvv {
    position: relative;
  }
}
.reactive-tooltip-icon {
  position: absolute;
  z-index: 3;
  top: 3em;
  right: 0.8em;
  display: block;
  width: 16px;
  height: 16px;
  padding: 0.3em;
  cursor: pointer;
  outline: none;
  &:before {
    display: block;
    border-radius: 50%;
    background: rgba(1, 19, 44, 0.85);
    color: #fff;
    content: "i";
    font-size: 10px;
    font-weight: 700;
    line-height: 16px;
    opacity: 1;
    text-align: center;
    transition: 0.3s ease all;
  }
}
