.dashboard-main-section {
  .container {
    max-width: 1132px;
    width: calc(100% - 40px);
    margin: 0 auto;

    .dashboard-box {
      box-shadow: 0 5px 100px rgba(0, 0, 0, 0.1);
      padding: 2rem;
      border-radius: 1.6rem;
      background-color: #fff;
      box-sizing: border-box;
      margin-top: 30px;

      @media only screen and (max-width: 767px) {
        padding: 1.25rem;
      }
    }

    .dashboard-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 30px;

      .setting-btn {
        a {
          text-decoration: none;
        }

        button {
          align-items: center;
          border: none;
          background-color: #1664fa;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 500;
          padding: 0.75rem 1.5rem;
          border-radius: 1.5rem;
          text-transform: capitalize;
          font-size: 0.85rem;
          letter-spacing: 1px;
          gap: 5px;
          cursor: pointer;

          img {
            width: 20px;
            filter: invert(1);
          }
        }
      }
    }

    .setting-find-number {
      .setting-billing-heading {
        display: flex;
        align-items: center;
        margin-bottom: 1rem !important;
        font-size: 0.85rem;
        line-height: 1.3;
        letter-spacing: 1px;

        .dot {
          display: inline-block;
          background-color: #1664fa;
          width: 8px;
          height: 8px;
          border-radius: 100%;
        }

        span:last-child {
          margin-left: 1rem;
          font-weight: 500;
          color: #000000;
          font-size: 25px;

          @media only screen and (max-width: 475px) {
            font-size: 20px;
          }
        }
      }

      .billing-form {
        display: flex;
        flex-direction: column;
        gap: 30px;

        @media only screen and (max-width: 767px) {
          gap: 15px;
        }

        label {
          color: #868686;
        }

        input {
          height: 40px;
          margin-top: 10px;
          color: #868686;
          font-weight: 500;

          @media only screen and (max-width: 767px) {
            margin-top: 5px;
          }
        }

        .ant-select {
          margin-top: 10px;

          @media only screen and (max-width: 767px) {
            margin-top: 5px;
          }

          .ant-select-selection-search {
            input {
              margin-top: 0;
            }
          }
        }

        .input-fx {
          display: flex;
          gap: 30px;

          .f-name-input {
            width: calc(50% - 15px);

            select {
              width: 100%;
              background-color: #ffff;
              border: 1px solid #d9d9d9;
              padding: 4px 11px;
              border-radius: 5px;
              height: 40px;
              font-size: 14px;
              margin-top: 10px;
              color: #868686;
              font-weight: 500;

              &:focus {
                outline: none;
              }
            }
          }

          .l-name-input {
            width: calc(50% - 15px);
          }
        }

        .save-billing-btn {
          button {
            justify-content: center;
            align-items: center;
            font-weight: 400;
            padding: 0.75rem 1.5rem;
            border-radius: 1.5rem;
            text-transform: capitalize;
            font-size: 0.85rem;
            line-height: 1.3;
            letter-spacing: 1px;
            display: flex;
            width: 100%;
            max-width: 138px;
            flex: 0 0 100%;
            color: #fff;
            box-shadow: 0 4px 20px rgb(22 100 250 / 50%);
            background-color: #1664fa !important;
            border: 0.5px solid transparent;
            cursor: pointer;

            @media only screen and (max-width: 425px) {
              max-width: 100%;
            }
          }
        }
      }
    }

    .billing-footer-btn {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      button {
        min-width: 138px;
        border: 0.5px solid #f3f3f3;
        color: #9f9f9f;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        padding: 0.75rem 1.5rem;
        border-radius: 1.5rem;
        text-transform: capitalize;
        font-size: 0.85rem;
        line-height: 1.3;
        letter-spacing: 1px;
        background-color: #ffffff !important;
        cursor: pointer;

        @media only screen and (max-width: 425px) {
          width: 100%;
          max-width: 100%;
        }
      }

      .logout-btn-wrap {
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
          rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
        border: 0.5px solid transparent;
        background-color: #000000 !important;
        color: #ffffff;

        @media only screen and (max-width: 425px) {
          margin-bottom: 15px;
        }
      }
    }
  }
}

.login-modal-section {
  .unsubscribe-modal-body {
    padding-top: 10px;
    text-align: center;

    .unsubscribe-text {
      font-size: 17px;
      color: #868686 !important;
    }

    .submit-btn-modal {
      width: 100%;
      margin-top: 30px;

      button {
        width: 100%;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        padding: 0.75rem 1.5rem;
        border: none;
        border-radius: 1.5rem;
        text-transform: capitalize;
        font-size: 0.85rem;
        line-height: 1.3;
        letter-spacing: 1px;
        color: #fff;
        display: flex;
        width: 100%;
        background-color: #1664fa !important;
        cursor: pointer;
      }
    }
  }
}

// Account Information Css

.account_dash_wrap {
  div {
    box-sizing: border-box;
  }

  .mt-50 {
    margin-top: 50px;
  }

  .account_bg_wrap {
    background: #fff;
    border-radius: 1.6rem;
    box-shadow: 0 5px 100px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
    padding: 30px;
  }

  .account_title {
    display: inline-block;
    vertical-align: top;
    width: 100%;

    h2 {
      display: inline-block;
      vertical-align: top;
      width: 100%;
      font-weight: 500;
      color: #000000;
      font-size: 25px;
      border-bottom: 1px solid #d9d9d9b3;
      font-style: normal;
      margin: 0px 0px 20px 0px;
      padding-bottom: 20px;
    }
  }

  .wpb_content_account {
    .account_info_inner {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      .wi-50 {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }

    .account_address {
      strong {
        color: #868686;
        display: inline-block;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 1.1;
        margin-bottom: 10px;
        text-transform: uppercase;
        vertical-align: top;
        width: 100%;
      }

      p {
        display: inline-block;
        margin: 0;
        vertical-align: top;
        width: 100%;

        a {
          align-items: center;
          color: #868686;
          column-gap: 150px;
          display: flex;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 1.1;
          width: 100%;
          text-decoration: unset;
        }
      }
    }
  }
}

.dashboard-main-section {
  .container {
    .account_dash_wrap {
      .setting-find-number {
        border-bottom: 1px solid rgb(217 217 217 / 70%);
        margin-bottom: 20px;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .account_dash_wrap {
    .account_title {
      h2 {
        font-size: 24px;
        margin-bottom: 15px;
        padding-bottom: 15px;
      }
    }

    .wpb_content_account {
      .account_address {
        p {
          a {
            column-gap: 15px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .account_dash_wrap {
    .mt-50 {
      padding: 1.25rem;
      margin-top: 30px;
    }

    .account_title {
      h2 {
        font-size: 20px;
      }
    }

    .wpb_content_account {
      .account_info_inner {
        flex-wrap: nowrap;
        justify-content: space-between;

        .wi-50 {
          flex: 0 0 max-content;
          max-width: max-content;
        }
      }

      .account_address {
        p {
          display: block;
          width: auto;
        }

        strong {
          width: auto;
        }
      }
    }
  }
}

@media only screen and (max-width: 475px) {
  .account_dash_wrap {
    .wpb_content_account {
      .account_info_inner {
        flex-wrap: wrap;
        row-gap: 20px;

        .wi-50 {
          flex: 0 0 100%;
          max-width: 100%;
        }

        .account_address {
          &:first-child {
            border-bottom: 1px solid #7777771a;
            padding-bottom: 20px;
          }
        }
      }
    }
  }
}

// New Language Css
.dashboard-main-section {
  .container {
    .setting-find-number {
      .billing-form {
        .input-fx {
          .f-name-input {
            select {
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6.122' height='3.561' viewBox='0 0 6.122 3.561'%3E%3Cpath d='m.707.707 2.354 2.354L5.415.707' fill='none' stroke='%2365676b' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
              background-repeat: no-repeat;
              background-size: 8px 6px;
              background-position: center right 10px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 475px) {
  .dashboard-main-section {
    .container {
      .setting-find-number {
        .billing-form {
          .input-fx {
            flex-wrap: wrap;
            gap: 15px;

            .f-name-input {
              width: 100%;
            }

            .l-name-input {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

// subscription SCSS
.account_bg_wrap,
.account_bg_wrap * {
  box-sizing: border-box;
}

.account_bg_wrap {
  background-color: #FFFFFF;
  border-radius: 1.6rem;
  box-shadow: 0 5px 100px #0000001a;
  padding: 30px 30px;
  margin-bottom: 30px;
}

.account_title {
  h2 {
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #000000;
    margin-bottom: 40px;
    margin-top: 0px;

    @media screen and (max-width: 575px) {
      font-size: 20px;
    }
  }
}

.ant-modal.tracelo_unsubscribe-modal {
  width: 100% !important;
  max-width: calc(595px - 30px) !important;

  @media screen and (max-width: 575px) {
    max-width: calc(100vw - 20px) !important;
  }
}

.tracelo_unsubscribe-modal {
  .ant-modal-content {
    padding: 0px 0px !important;
  }
}

.theme_dir__rtl {
  .subscriptions_table {
    table {
      tbody {
        td {
          .cancel_btn {
            text-align: right;
          }
        }
      }
    }
  }
}

.tracelo_unsubscribe-modal {
  .login-modal-section {
    .unsubscribe_title {
      h2 {
        font-size: 24px;
        line-height: 1.1;
        margin: 0px 0px;
        margin-bottom: 15px;
        color: #000000;
        text-align: center;

        @media screen and (max-width: 575px) {
          font-size: 20px;
        }
      }
    }

    .unsubscribe-modal-body {
      .submit-btn-modal {
        button {
          background-color: #24537b !important;
          box-shadow: none;
        }
      }

      .back-btn-modal {
        width: 100%;
        display: inline-block;
        vertical-align: top;
        margin-top: 20px;

        button {
          background-color: transparent;
          outline: unset;
          border: unset;
          width: 100%;
          display: inline-block;
          vertical-align: top;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          color: #777777;
          text-transform: capitalize;
          padding: 0px 0px;
          cursor: pointer;
        }
      }
    }
  }
}

.wpb_content_billing {
  .form_group_warp {
    form {
      .account-container {
        .field-account {
          select.ant-input-prefix {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            padding-right: 13px !important;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6.122' height='3.561' viewBox='0 0 6.122 3.561'%3E%3Cpath d='m.707.707 2.354 2.354L5.415.707' fill='none' stroke='%2365676b' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
            background-position: 97% center;
            background-repeat: no-repeat;
            background-size: 12px 7px;
          }
        }
      }
    }
  }
}

div.ant-input-prefix {
  height: 50.5px;

  @media screen and (max-width: 625px) {
    height: 43.4px !important;
  }

  .ant-select-selector {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #dfeaee !important;
    background: rgba(236, 247, 251, 0.5) !important;
    padding: 16px 13px;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.1;
    outline: none;
    color: #000;
    appearance: none;
    -webkit-appearance: none;
    height: 50.5px !important;

    @media screen and (max-width: 625px) {
      height: 43.4px !important;
    }

    input {
      height: 50.5px !important;

      @media screen and (max-width: 625px) {
        height: 43.4px !important;
      }
    }
  }
}

.tracelo_unsubscribe-modal {
  .login-modal-section {
    margin: 18px 0px;

    @media screen and (max-width: 576px) {
      margin: 18px 5px;
    }
  }
}

.unsubscribe_ButtonWrapper {
  button.unsubscribe_ClrButton {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    text-transform: capitalize;
    font-size: 0.85rem;
    line-height: 1.3;
    letter-spacing: 1px;
    width: 100%;
    cursor: pointer;
    border-radius: 5px;
    padding: 14px 15px;
    color: #24537B;
    margin-top: 15px;
    background-color: #E2F4F9 !important;
    border: 1px solid #E2F4F9;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: transparent !important;
      border-color: #24537B;
    }
  }
}

.subscriptions_table {
  table {
    width: 100%;
    border-collapse: collapse;

    tbody {
      td {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.1;
        color: #444444;
        padding: 15px 7px 5px;
        text-align: left;

        span {
          font-weight: 400;
        }

        &::before {
          content: attr(data-title);
          font-size: 0px;
          font-style: normal;
          font-weight: 600;
          line-height: 1.1;
          color: #333333;
        }

        .tracking_btn {
          font-size: 11px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0.5px;
          color: #ffffff;
          border-radius: 3px;
          background-color: #1664FA;
          border: 1px solid #1664FA;
          padding: 5px 6px;
          outline: none;
          box-sizing: unset;

          &.more_report_btn {
            background-color: #008000;
            border: none;
          }
        }

        img {
          width: 100%;
          max-width: 20px;
          height: 100%;
          max-height: 20px;
          display: inline-block;
          vertical-align: middle;
        }

        .cancel_btn {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 1.1;
          color: #24537B;
          background-color: transparent;
          outline: none;
          box-shadow: unset;
          border: 0px;
          padding: 0px 0px;
          text-align: left;
          cursor: pointer;
        }
      }

      td.cancel_subscription {
        text-align: center;
      }
    }

    thead {
      tr {
        border-bottom: 1px solid rgb(217 217 217 / 50%);

        th {
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 1.1;
          color: #333333;
          text-align: left;
          padding: 7px 7px 14px;
        }
      }
    }
  }
}

.theme_dir__rtl {
  .subscriptions_table {
    table {
      tbody,
      thead {
        td,
        th {
          text-align: right;
        }
      }
    }
  }
}

.error-message {
  color: red;
  margin-top: 2px;
}

.required-field {
  color: red;
}

.red-bg {
  background-color: #FFD6D6;
  color: #E8505B;
  padding: 4px 10px;
  border-radius: 3px;
  font-size: 11px;
}

.green-bg {
  background-color: #C5F8DC;
  color: #44B678;
  padding: 6px 6px;
  border-radius: 3px;
  font-size: 11px;
}

.green-text {
  color: #44B678;
  font-size: 12px;
}

.mixed-status {
  display: inline-flex;
  gap: 5px;
  align-items: center;
}

.default-status {
  color: #444444;
}

@media only screen and (max-width: 767px) {
  .subscriptions_table {
    table {
      tbody {
        tr {
          width: 100%;
          padding: 15px 15px;
          display: block;
          border-radius: 5px;
          border: 1px dashed#24537B;
          background: #FFFFFF;

          &:not(:last-child) {
            margin-bottom: 20px;
          }

          td {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(1, 50% 50%);
            align-items: center;

            &::before {
              font-size: 14px;
              margin-right: 7px
            }

            &:not(:last-child) {
              border-bottom: 1px solid rgb(217 217 217 / 50%);
            }
          }
        }
      }

      thead {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .account_title {
    h2 {
      margin-bottom: 0px;
      margin-top: 0px;
    }
  }

  .subscriptions_table {
    table {
      tbody {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        td {
          span {
            font-weight: 500;
          }

          span.green-bg {
            width: max-content;
          }

          span.red-bg {
            width: max-content;
          }
        }

        >tr {
          border: 1px dashed #24537B !important;
          max-width: 100%;
          flex: 0 0 100%;
          margin-top: 20px;
          margin-bottom: 0px !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 875px) {
  .subscriptions_table {
    table {
      tbody {
        >tr {
          max-width: 100%;
          flex: 0 0 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 475px) {
  .subscriptions_table {
    table {
      tbody {
        td {
          span.mixed-status {
            flex-wrap: wrap;
            width: 100%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .subscriptions_table {
    table {
      tbody {
        td {
          padding: 15px 7px 15px;
          > span {
            display: block;
          }
        }

        td.cancel_subscription {
          &::before {
            display: none;
          }
        }
      }
    }
  }

  .subscriptions_table {
    table {
      tbody {
        tr {
          td {
            &:last-child {
              grid-template-columns: repeat(1, 100% 100%);
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .wpb_content_subs {
    padding: 1.25rem;
  }
}

// Subscriptions Teg Design 
.subscriptions_table{
  table{
    tbody{
      td{
        span.tracking_config_wrapper{
          margin: -4px -4px;
          padding-bottom: 5px;
          span.tracking_environment{
            padding: 4px 4px;
            display: inline-block;
            vertical-align: top;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .theme_dir__rtl {
    .subscriptions_table {
      table {
        tbody {
          tr {
            td {
              &::before {
                margin-right: 0px;
                margin-left: 7px;
              }
            }
          }
        }
      }
    }
  }
}