@font-face {
  font-family: "GT Walsheim Pro";
  src: local("GT Walsheim Pro Regular"), local("GT-Walsheim-Pro-Regular"),
    url("../assets/font/GTWalsheimPro-Regular.woff2") format("woff2"),
    url("../assets/font/GTWalsheimPro-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap; 
}

* {
  font-family: "GT Walsheim Pro";
}

html {
  -webkit-tap-highlight-color: transparent;
}

body {
  padding: 0;
  margin: 0;
}

.container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}
.m-0{
  margin: 0px;
}
.p-0{
  padding: 0px;
}
.txt-capitalize{
  text-transform: capitalize;
}
.d-inline-block{
  display: inline-block;
}
.back-grad-title {
  padding-top: 200px;
  padding-bottom: 80px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 40px;
  background-image: linear-gradient(to right, #5047ef, #e558a0);
  border-bottom-left-radius: 65px;
  border-bottom-right-radius: 65px;

  @media only screen and (max-width: 767px) {
    padding-top: 170px;
    padding-bottom: 60px;
  }

  .pricing-main-title {
    font-size: 65px;
    text-align: center;
    color: white;

    @media only screen and (max-width: 767px) {
      font-size: 30px;
      word-wrap: break-word;
    }
  }
}

.CardField-expiry {
  transform: none !important;
}

.hl_cta_wrap {
  position: relative;
  overflow: hidden;
  width: max-content;
  height: 100%;
  padding: 12px 20px;
  border: 1px solid currentColor;
  border-radius: 14px;
  transition: 0.5s ease-in-out;
  z-index: 1;
  background-color: transparent;
  color: #1664fa;
  font-weight: 700;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: #fff !important;
  }
}

.hl_cta_wrap {
  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: 0.5s ease-in-out;
    z-index: -1;
  }
}

.hl_cta_wrap {
  &::after {
    left: -50px;
    border-left: 50px solid transparent;
    border-top: 80px solid #1664fa;
    transform: translateX(100%);
  }

  &::before {
    right: -50px;
    border-right: 50px solid transparent;
    border-bottom: 80px solid #1664fa;
    transform: translateX(-100%);
  }

  &:hover {
    color: #ffffff;

    &::after {
      transform: translateX(49%);
    }

    &::before {
      transform: translateX(-49%);
    }
  }
}

.errorInput {
  border-color: red;
}

.hl_cta_wrap-white {
  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: 0.5s ease-in-out;
    z-index: -1;
  }
}

.hl_cta_wrap-white {
  &::after {
    left: -50px;
    border-left: 50px solid transparent;
    border-top: 80px solid #ffffff;
    transform: translateX(100%);
  }

  &::before {
    right: -50px;
    border-right: 50px solid transparent;
    border-bottom: 80px solid #fff;
    transform: translateX(-100%);
  }

  &:hover {
    color: #1664fa !important;

    &::after {
      transform: translateX(49%);
    }

    &::before {
      transform: translateX(-49%);
    }
  }
}

:where(.css-dev-only-do-not-override-k7429z).ant-notification
  .ant-notification-notice
  .ant-notification-notice-message {
  height: max-content;
  margin-bottom: 0 !important;
  display: flex;
  align-items: center;
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.overly-loader {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1905px;
  width: 100%;
  min-height: 100%;
  height: 800px;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 100;
  top: 0;
  left: 0;
}

.ant-input {
  color: #000000;
  font-family: "GT Walsheim Pro";
}

.overly-loader {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1905px;
  width: 100%;
  min-height: 100%;
  height: 800px;
  background-color: rgba(255, 255, 255, 0.425);
  z-index: 100;
  top: 0;
  left: 0;
}

.errorFelid {
  border-color: red !important;
}

//Faqs Css

@media only screen and (max-width: 767px) {
  .bg-grd-section {
    .frequently-asked-question {
      .question-collapse {
        .collapse-box {
          padding: 15px 10px;

          .collapse-box-df-title {
            .collapse-box-title {
              p {
                font-size: 16px !important;
                line-height: 1.2;
              }
            }
          }
        }
      }
    }
  }

  .bg-grad-section {
    .home-special-offer {
      .specialOffer-container {
        .special-offerbox-partition {
          .bottom-btn {
            height: inherit;
          }
        }
      }
    }
  }
}

// New Signup Css

.signup__locating_wrap {
  .login-modal-title {
    p {
      @media only screen and (max-width: 475px) {
        font-size: 18px !important;
      }
    }
  }
  .wrap-top-modal {
    .google-button-login {
      width: 100%;
      max-width: 380px;
      margin: 0px auto;
      padding-bottom: 0px !important;
      @media only screen and (max-width: 474px) {
        max-width: 100%;
      }
      .login-with-google-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 7px;
        color: #777;
        font-family: "GT Walsheim Pro";
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        background-position: 15px center;
        padding: 14px 15px;
        border: 1px solid rgba(209, 209, 215, 0.6);
        padding-left: 20px !important;
        @media only screen and (max-width: 474px) {
          font-size: 14px;
          padding: 13px 15px;
        }
      }
      .login-with-apple-btn {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'%3E%3Cpath d='M11.6305 2.73564C12.3078 2.01055 12.7659 1.00386 12.6405 0C11.6647 0.0348222 10.4821 0.572776 9.78175 1.29781C9.1539 1.93967 8.60421 2.96551 8.75091 3.94967C9.83972 4.02332 10.9521 3.45956 11.6305 2.73564ZM13.7085 9.66829C13.6877 7.41775 15.4903 6.33684 15.5701 6.28461C14.5567 4.75632 12.9784 4.5472 12.4163 4.52362C11.0724 4.38254 9.79589 5.33749 9.11358 5.33749C8.43405 5.33749 7.38297 4.54214 6.26835 4.5635C4.80303 4.58764 3.45287 5.44091 2.7003 6.79323C1.17813 9.51375 2.31016 13.5438 3.79344 15.7505C4.51853 16.8308 5.38186 18.0427 6.51556 17.9988C7.60993 17.955 8.02248 17.271 9.34283 17.271C10.6637 17.271 11.0336 17.9988 12.1892 17.9769C13.365 17.955 14.1092 16.8758 14.8281 15.7921C15.66 14.5393 16.0029 13.3263 16.0225 13.265C15.9973 13.2504 13.7326 12.3595 13.7085 9.66829Z' fill='white'/%3E%3C/svg%3E");
        background-color: #000000;
        color: #ffffff;
        border: 1px solid #000000;
        margin: 10px 0px;
      }
      .login-with-facebook-btn {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'%3E%3Cg clip-path='url(%23clip0_3308_946)'%3E%3Cpath d='M18 9C18 13.4923 14.7087 17.2157 10.4062 17.8907V11.6016H12.5033L12.9023 9H10.4062V7.3118C10.4062 6.59988 10.755 5.90625 11.873 5.90625H13.0078V3.69141C13.0078 3.69141 11.9777 3.51562 10.993 3.51562C8.93742 3.51562 7.59375 4.76156 7.59375 7.01719V9H5.30859V11.6016H7.59375V17.8907C3.29133 17.2157 0 13.4923 0 9C0 4.02961 4.02961 0 9 0C13.9704 0 18 4.02961 18 9Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_3308_946'%3E%3Crect width='18' height='18' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
        background-color: #4096ff;
        color: #ffffff;
        border: 1px solid #4096ff;
      }
    }
    .auth-divider {
      @media only screen and (max-width: 576px){
        justify-content: center;
        text-align: center;
      }
      .line{
        @media only screen and (max-width: 576px){
          display: none;
        }
      }
      span.or-text {
        color: rgba(0, 0, 0, 0.65);
        font-size: 13.6px;
        font-weight: 400;
        letter-spacing: 0.2px;
        @media only screen and (max-width: 576px) {
          margin: 0px 0px !important;
        }
      }
    }
  }
}

.signup__locating_wrap {
  .wrap-top-modal {
    .body-modal {
      .modal-login-form {
        .term-checkbox {
          span,
          a {
            color: rgba(0, 0, 0, 0.6) !important;
          }
        }
      }
    }
  }
}

.home_locating_wrap {
  .login-modal-section {
    .wrap-top-modal {
      .body-modal {
        .submit-btn-modal {
          .hl_cta_wrap {
            border-radius: 7px;
            padding: 12px 20px;
            font-weight: 500;
            font-size: 18px;
          }
        }
      }
    }
  }
}

.signup__locating_wrap {
  .wrap-top-modal {
    .auth-divider {
      span.or-text {
        color: #000000a6;
        font-size: 13.6px;
        font-weight: 400;
        letter-spacing: 0.2px;
        @media screen and (max-width: 576px){
          font-size: 11px;
        }
      }
    }
  }
}

@media screen and (min-width: 475px) {
  .signup__locating_wrap {
    .wrap-top-modal {
      margin-top: 20px;
      padding: 0px 20px;
    }
  }
}
.no-scroll {
  overflow: hidden !important;
  height: 100% !important;
  position: fixed !important;
  width: 100% !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 9999s ease-in-out 0s;
}

// Login Popup

.login__locating_wrap {
  .login-modal-section {
    padding: 0px 0px !important;
    .login-modal-title {
      max-width: 180px;
      word-break: break-word;
      line-height: normal;
    }
    .wrap-top-modal {
      margin-top: 40px;
    }
  }
}
@media only screen and (max-width: 575px) {
  .login__locating_wrap {
    .login-modal-section {
      .wrap-top-modal {
        margin-top: 30px;
      }
    }
  }
}


// Sign Up Popup
.home_locating_wrap {
  .login-modal-section {
    padding: 0px !important;
    .modal_number_info {
      .login-modal-title {
        max-width: 180px;
        word-break: break-word;
        line-height: normal;
        @media only screen and (max-width: 576px){
          min-width: 180px;
        }
        p {
          white-space: break-spaces;
          line-height: normal !important;
        }
      }
    }
    .wrap-top-modal {
      margin-top: 60px;
      .body-modal {
        padding: 0px 0px !important;
        margin: 0px 0px !important;
      }
    }
  }
}
.login__locating_wrap {
  .login-modal-section {
    padding: 0px 0px !important;
    .wrap-top-modal {
      margin-top: 40px;
    }
  }
}
@media only screen and (max-width: 575px) {
  .login__locating_wrap {
    .login-modal-section {
      .wrap-top-modal {
        margin-top: 30px;
      }
    }
  }
  .home_locating_wrap {
    .login-modal-section {
      .wrap-top-modal {
        margin-top: 50px;
      }
    }
  }
}