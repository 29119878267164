.footer {
  width: 100%;

  .footer-container {
    padding: 0 50px 40px;

    .footer-section {
      background-color: #0a2540;
      border-bottom-left-radius: 25px;
      border-bottom-right-radius: 25px;

      .footer-inner-container {
        width: 100%;
        max-width: 1650px;
        margin: 0 auto;
        padding-top: 50px;

        .df-footer {
          display: flex;
          justify-content: space-between;
          padding-bottom: 40px;
          margin: 0 50px;

          .footer-left-contan {
            width: 25%;

            .df-collogo-desc {
              display: flex;
              flex-direction: column;
              gap: 20px;

              .footer-logo {
                cursor: pointer;
              }

              .footer-desc {
                color: white;
                font-weight: 400;
                font-size: 18px;
                line-height: 24px;

                p {
                  margin: 0;
                }
              }
            }
          }

          .footer-right-contan {
            width: 25%;

            .df-right-footer-menu {
              display: flex;
              justify-content: space-around;

              .legal-info {
                margin-bottom: 20px;
              }

              .legal-title {
                color: white;
                font-weight: 800;
                font-size: 18px;
                line-height: normal;
                min-height: 52px;
                @media only screen and (max-width: 575px) {
                  font-size: 16px;
                }

                p {
                  margin-top: 0;

                  @media only screen and (max-width: 1440px) {
                    min-height: 40px;
                    margin-bottom: 10px;
                  }
                }
              }

              .legal-menus {
                ul {
                  padding: 0;

                  li {
                    cursor: pointer;
                    list-style: none;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 1.5;
                    color: white;
                    @media only screen and (max-width: 575px) {
                      font-size: 13px;
                    }
                    a {
                      color: white;
                      text-decoration: none;
                    }
                  }
                }
              }
            }
          }
        }

        .footer-bottom-contant {
          padding: 0 45px 15px;

          .footer-bottom-flex {
            padding: 15px 0 0;
            border-top: 0.5px solid;
            border-color: #ffffff;
            display: flex;
            justify-content: space-between;

            p {
              margin: 0;
            }

            .tracelo-footer {
              text-align: left;
              color: white;
              canvas {
                filter: brightness(0.5);
                pointer-events: none;
                width: 225px !important;
                height: 15px !important;
                margin: 0 !important;
                margin-top: 5px !important;
              }
            }

            @media screen and (max-width: 620px) {
              .policies-footer {
                row-gap: 10px;
                column-gap: 15px;
                justify-content: space-around;
              }
            }

            @media screen and (max-width: 400px) {
              .policies-footer {
                text-align: center;
                font-size: 13px;
              }
            }

            .policies-footer {
              display: flex;

              .cookies-policy {
                a {
                  color: white;
                  text-decoration: none;
                }

                p {
                  cursor: pointer;

                  &::after {
                    content: "|";
                    margin: 0 20px;
                  }
                }
              }

              .tems-policy {
                cursor: pointer;

                a {
                  color: white;
                  text-decoration: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1199px) {
  .df-footer {
    .footer-left-contan {
      width: 40% !important;
    }

    .footer-right-contan {
      width: 40% !important;
    }
  }
}

@media only screen and (max-width: 800px) {
  .footer-container {
    padding: 0 30px 40px !important;
  }

  .df-footer {
    .footer-left-contan {
      width: 45% !important;
    }

    .footer-right-contan {
      width: 45% !important;
    }
  }

  .footer-bottom-flex {
    flex-direction: column;
    align-items: center;
    .tracelo-footer {
      margin-bottom: 15px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .footer .footer-container .footer-section .footer-inner-container .df-footer {
    margin: 0 20px;
  }

  .footer
    .footer-container
    .footer-section
    .footer-inner-container
    .footer-bottom-contant {
    padding: 0 20px 15px;
  }

  .footer
    .footer-container
    .footer-section
    .footer-inner-container
    .df-footer
    .footer-right-contan {
    .df-right-footer-menu {
      justify-content: space-between !important;

      > div {
        width: 50%;
        flex: 0 0 50%;
      }
    }
  }
}

@media only screen and (max-width: 650px) {
  .df-footer {
    gap: 30px;
    flex-direction: column;

    .footer-left-contan {
      width: 100% !important;
    }

    .footer-right-contan {
      width: 100% !important;
    }
  }
}

@media only screen and (max-width: 620px) {
  .policies-footer {
    p {
      &::after {
        content: none !important;
        margin: 0 !important;
      }
    }
  }
}

@media only screen and (max-width: 590px) {
  .footer-section {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .footer-container {
    padding: 0 !important;
  }

  .policies-footer {
    font-size: 13px;
  }
}

@media only screen and (max-width: 480px) {
  .df-footer {
    gap: 30px;
    flex-direction: column;

    .footer-left-contan {
      width: 100% !important;
    }
  }
}
// New Css

.bts__language_wrap {
  .bg-grad-section {
    .Locate-Any-Phone {
      .wid-home-banner {
        .df-first-section {
          .right-contant {
            .banner-img {
              .location-banner-img-text {
                width: 100%;
                max-width: 40%;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1190px) {
  .bts__language_wrap {
    .bg-grad-section {
      .how-work-section {
        .how-work-container {
          .how-work-body {
            .how-work-desc-sections {
              .box {
                .how-work-contant {
                  .left-contant {
                    .how-work-box-title {
                      font-size: 20px !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .bg-grd-section {
      .frequently-asked-question {
        .question-collapse {
          .collapse-box {
            .collapse-box-df-title {
              .collapse-box-icon {
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1024px) {
  .bts__language_wrap {
    .bg-grad-section {
      .Locate-Any-Phone {
        .wid-home-banner {
          .df-first-section {
            .right-contant {
              .banner-img {
                .location-banner-img-text {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 930px) {
  .bts__language_wrap {
    .how-work-desc-sections {
      .group-box-3 {
        width: calc(100% - 10px) !important;
      }
    }
    .bg-grad-section {
      .Locate-Any-Phone {
        .wid-home-banner {
          .df-first-section {
            .left-contant {
              .wrap-right-input-error {
                p {
                  top: -25px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 375px) {
  .bts__language_wrap {
    .bg-grad-section {
      .home-special-offer {
        .specialOffer-container {
          .special-offerbox-partition {
            .bottom-btn {
              height: auto;
            }
          }
        }
      }
    }
  }
}

.choose-tracelo-section {
  .choose-tracelo-container {
    .wrap-choose-tracelo {
      .choose-tracelo-under-section {
        .df-wrap-service {
          .choose-tracelo-box {
            .choose-tracelo-part {
              .choose-tracelo-box-title {
                height: 100%;
                min-height: 70px;
                margin-bottom: 10px;
                @media only screen and (max-width: 1024px) {
                  min-height: 50px;
                }
              }
              .choose-tracelo-box-desc {
                margin-top: 0px !important;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .choose-tracelo-section {
    .choose-tracelo-container {
      .wrap-choose-tracelo {
        .choose-tracelo-under-section {
          .df-wrap-service {
            .choose-tracelo-box {
              .choose-tracelo-part {
                .choose-tracelo-box-title {
                  min-height: inherit;
                }
              }
            }
          }
        }
      }
    }
  }
}

// SCSS For Footer Policy

.footer {
  .df-footer {
    .footer-right-contan {
      .df-right-footer-menu {
        .top_policy_menu {
          display: none;
        }
      }
    }
  }
}
@media only screen and (max-width: 991px) {
  .footer {
    .df-footer {
      flex-wrap: wrap;
      .footer-left-contan {
        width: 100% !important;
        .df-collogo-desc {
          .footer-desc {
            max-width: 350px;
            width: 100%;
          }
        }
      }
      .footer-right-contan {
        width: 100% !important;
        border-top: 1px solid rgb(255 255 255 / 30%);
        margin-top: 30px;
        padding-top: 30px;
        .df-right-footer-menu {
          margin: 0px -10px;
          box-sizing: border-box;
          flex-wrap: wrap;
          justify-content: space-between !important;
          .top_policy_menu {
            display: block;
          }
          .legal-info {
            padding: 0px 10px;
            box-sizing: border-box;
            margin: 0px 0px !important;
          }
          .account-menu {
            padding: 0px 10px;
            box-sizing: border-box;
          }
        }
      }
    }
    .footer-container {
      .footer-section {
        .footer-inner-container {
          .df-footer {
            .footer-right-contan {
              .df-right-footer-menu {
                .legal-menus {
                  ul {
                    li {
                      a {
                        p {
                          margin: 0px 0px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .footer-bottom-contant {
            .footer-bottom-flex {
              .policies-footer {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .footer {
    .df-footer {
      .footer-right-contan {
        .df-right-footer-menu {
          .top_policy_menu {
            width: 100% !important;
            flex: 0 0 100% !important;
            border-top: 1px solid rgb(255 255 255 / 30%);
            margin-top: 30px;
            padding-top: 30px;
          }
        }
      }
    }
  }
}
