.transaction_form {
  form {
    .form-container-payment {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 20px;
      row-gap: 16px;
      margin-bottom: 10px;
      .field-container {
        &:first-child {
          grid-column: 1 / 3;
        }
        label {
          display: none;
        }
        .cq_panel__components {
          width: 100%;
          border-radius: 5px;
          border: 1px solid #dfeaee;
          background: rgba(236, 247, 251, 0.5);
          padding: 16px 13px;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 1.1;
          outline: none;
          color: #9ea8bd;
          &:placeholder {
            color: #9ea8bd;
          }
          box-sizing: border-box;
        }
        .cq_panel__components {
          background-size: 18px;
          background-repeat: no-repeat;
        }
        .cq_panel__components.main-phone-card {
          padding-left: 45px;
          background-position: 13px;
          background-image: url("../../../../assets/home/credit-card-icon.svg");
        }
        .cq_panel__components.main-phone-date {
          background-position: 13px center;
          padding-left: 45px;
          background-image: url("../../../../assets/home/form-date-icon.svg");
        }
        .cq_panel__components.main-phone-cvv {
          background-position: 13px center;
          padding-left: 45px;
          background-image: url("../../../../assets/home/form_cvv_icon.svg");
        }
      }
      &.solidGate_method{
      grid-template-columns: none;
      }
    }
    .stripe_error {
      color: #eb1c26;
      margin: 0;
    }
    .submit_button {
      width: 100%;
      height: 100%;
      display: inline-block;
      vertical-align: top;
      text-align: center;
      margin-bottom: 25px;
      margin-top: 30px;
      button.payment__Submit {
        display: inline-block;
        vertical-align: top;
        padding: 10px 80px;
        background-color: #4096ff;
        border: 1px solid #4096ff;
        color: #ffffff;
        border-radius: 10px;
        outline: unset;
        font-size: 16px;
        line-height: 1.5;
        font-style: normal;
        font-weight: 500;
        text-transform: capitalize;
        cursor: pointer;
      }
    }
  }
}

.reactivate__payment_wrap {
  .mpo_modal_content {
    width: 100%;
    max-width: 430px;
    margin: 0px auto 40px;
    text-align: center;
    h4 {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;
      color: #000000;
      margin: 0px 0px 15px;
    }
    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.6;
      color: #777777;
      margin: 0px 0px;
    }
  }
  .ant-modal-content {
    padding: 0px 0px !important;
    .ant-modal-body {
      padding: 50px 20px !important;
    }
  }
  .subscription_content_wrap {
    p {
      width: 100%;
      max-width: 360px;
      margin: 0px auto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      color: #777777;
      line-height: 1.4;
      text-align: center;
    }
  }
}

@media screen and (max-width: 575px) {
  .reactivate__payment_wrap {
    .mpo_modal_content {
      margin-bottom: 20px;
    }
    .ant-modal-content {
      .ant-modal-body {
        padding: 20px 10px !important;
      }
    }
  }
  .transaction_form {
    form {
      .form-container-payment {
        margin-bottom: 20px;
        grid-template-columns: repeat(1, 1fr);
        .field-container {
          &:first-child {
            grid-column: unset;
          }
        }
      }
    }
  }
}
